import { yupResolver } from '@hookform/resolvers/yup';
import {
  Address,
  AircraftType,
  AircraftVariant,
  BatteryType,
  BatteryTypeVariant,
  CargoCooling,
  CargoTemperatureRange,
  CargoType,
  DimensionUnit,
  Document,
  HandlingOptions,
  PackingGroup,
  PackingInstruction,
  Section,
  ServiceType,
  WeightUnit,
} from '@pelicargo/types';
import { useError, useSuccess } from '@pelicargo/ui';
import { transformNumber } from '@pelicargo/utils';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useSessionStorage } from 'usehooks-ts';
import * as Yup from 'yup';

import { TrpcInputs } from '../../config/trpc';

type EmptyString = '';

export enum DGDTab {
  DGD = 'DGD',
  MSDS = 'MSDS',
}

export type CargoItem = {
  quantity: number;
  length: number;
  width: number;
  height: number;
  weight: number;
  handling: HandlingOptions;
  packing: HandlingOptions[];
};

export type RequestFormValues = {
  origin_airports: string[];
  destination_airports: string[];
  dropoff_by: string;
  commodity: string;
  original_dimension_unit: DimensionUnit;
  original_weight_unit: WeightUnit;
  is_known_shipper: boolean;
  cargo_origin?: Address;
  cargo_details: string;
  cargo_type: CargoType;
  cargo_temperature_range?: CargoTemperatureRange | EmptyString;
  cargo_cooling?: CargoCooling | EmptyString;
  aircraft_types: string[];
  cargo: CargoItem[];
  // TODO: Make part of form
  total_pieces: number;
  volumetric_weight: number;
  flight: string;
  service_type: ServiceType;
  gross_weight: number;

  // DGD
  un_number: string;
  class: string;
  packing_group: PackingGroup | EmptyString;
  aircraft_variant: AircraftVariant | EmptyString;
  packing_instruction: PackingInstruction | EmptyString;
  section: Section | EmptyString;
  battery_type: BatteryType | EmptyString;
  battery_type_variant: BatteryTypeVariant | EmptyString;
  dgdTab: DGDTab;
  // documents
  documents: Partial<Document>[];
};

export type RequestForm = Partial<NonNullable<TrpcInputs['createRequest']>>;

type UseRequestForm = { methods: UseFormReturn<RequestFormValues> };

const validationSchema = Yup.object({
  origin_airports: Yup.array()
    .of(Yup.string())
    .required('This field is required')
    .min(1, 'At least one origin airport is required')
    .max(4),
  destination_airports: Yup.array()
    .of(Yup.string())
    .required('This field is required')
    .min(1, 'At least one destination airport is required')
    .max(4),
  // .notRequired() needed for email ingestion prefill
  cargo_origin: Yup.object({
    line_one: Yup.string().notRequired(),
    line_two: Yup.string().notRequired(),
    city: Yup.string().notRequired(),
    state: Yup.string().notRequired(),
    zip_code: Yup.string().notRequired(),
    country: Yup.string().notRequired(),
  }).notRequired(),
  dropoff_by: Yup.date().nullable(),
  cargo_type: Yup.mixed()
    .oneOf(Object.values(CargoType))
    .required('This field is required'),
  is_known_shipper: Yup.boolean().required('This field is required'),
  commodity: Yup.string().nullable(),

  cargo_details: Yup.string().required('This field is required'),
  original_dimension_unit: Yup.string()
    .oneOf(Object.values(DimensionUnit))
    .required('This field is required'),
  original_weight_unit: Yup.string()
    .oneOf(Object.values(WeightUnit))
    .required('This field is required'),

  // Perishable

  cargo_temperature_range: Yup.string()
    .test('section-test', 'This field is required', (value, context) => {
      const { cargo_type: cargoType } = context?.parent || {};

      if (cargoType === CargoType.Perishable) {
        return Object.values(CargoTemperatureRange).includes(
          value as CargoTemperatureRange,
        );
      }
      return true;
    })
    .nullable(),
  cargo_cooling: Yup.string()
    .test('section-test', 'This field is required', (value, context) => {
      const { cargo_type: cargoType } = context?.parent || {};

      if (cargoType === CargoType.Perishable) {
        return Object.values(CargoCooling).includes(value as CargoCooling);
      }
      return true;
    })
    .nullable(),

  aircraft_types: Yup.array().of(Yup.string()).optional(),

  cargo: Yup.array()
    .of(
      Yup.object({
        quantity: Yup.number()
          .transform(transformNumber)
          .required('This field is required'),
        length: Yup.number()
          .transform(transformNumber)
          .min(1, 'This field is required')
          .required('This field is required'),
        width: Yup.number()
          .transform(transformNumber)
          .min(1, 'This field is required')
          .required('This field is required'),
        height: Yup.number()
          .transform(transformNumber)
          .min(1, 'This field is required')
          .required('This field is required'),
        weight: Yup.number()
          .when('cargo_details', {
            is: 'Weight Per Piece',
            then: () =>
              Yup.number()
                .transform(transformNumber)
                .min(1, 'This field is required')
                .required('This field is required'),
          })
          .transform(transformNumber)
          .notRequired(),

        handling: Yup.string(),
        packing: Yup.array().of(Yup.string()),
      }),
    )
    .min(1, 'You must have at least one piece of cargo'),

  // DGD
  un_number: Yup.string()
    .test('un_number-test', 'This field is required', (value, context) => {
      const {
        cargo_type: cargoType,
        battery_type: batteryType,
        dgdTab,
      } = context?.parent || {};
      if (dgdTab === DGDTab.DGD) return true;

      if (cargoType === CargoType.DangerousGoods) {
        if (!value) return false;
        if (!/^\d{4}$/.test(value)) return false;
      }

      if (
        cargoType === CargoType.Batteries &&
        batteryType === BatteryType.DG_BATTERIES_IN_EQUIPMENT
      ) {
        if (!value) return false;
        if (!['3480', '3481', '3090', '3091', '3171'].includes(value)) {
          return false;
        }
      }

      if (
        cargoType !== CargoType.DangerousGoods &&
        cargoType !== CargoType.Batteries &&
        value
      ) {
        return false;
      }

      return true;
    })
    .nullable(),

  class: Yup.string()
    .test('class-test', 'This field is required', (value, context) => {
      const { cargo_type: cargoType, dgdTab } = context?.parent || {};
      if (dgdTab === DGDTab.DGD) return true;
      if (cargoType === CargoType.DangerousGoods) {
        return /^\d{1,2}(\.\d{1,2})?$/.test(value);
      }
      return true;
    })
    .nullable(),

  packing_group: Yup.mixed<PackingGroup>().nullable(),

  aircraft_variant: Yup.string()
    .test('aircraft-test', 'This field is required', (value, context) => {
      const {
        cargo_type: cargoType,
        battery_type: batteryType,
        dgdTab,
      } = context?.parent || {};
      if (dgdTab === DGDTab.DGD) return true;

      if (
        cargoType === CargoType.DangerousGoods ||
        batteryType === BatteryType.DG_BATTERIES_IN_EQUIPMENT
      ) {
        return [AircraftVariant.CAO, AircraftVariant.PAX].includes(
          value as AircraftVariant,
        );
      }
      return true;
    })
    .nullable(),

  packing_instruction: Yup.string()
    .test(
      'packingInstruction-test',
      'This field is required',
      (value, context) => {
        const { battery_type: batteryType, dgdTab } = context?.parent || {};
        if (dgdTab === DGDTab.DGD) return true;

        if (
          dgdTab === DGDTab.MSDS &&
          batteryType === BatteryType.DG_BATTERIES_IN_EQUIPMENT
        ) {
          return [
            PackingInstruction.PI965,
            PackingInstruction.PI966,
            PackingInstruction.PI967,
            PackingInstruction.PI968,
            PackingInstruction.PI969,
            PackingInstruction.PI970,
          ].includes(value as PackingInstruction);
        }
        return true;
      },
    )
    .nullable(),

  section: Yup.string()
    .test('section-test', 'This field is required', (value, context) => {
      const { battery_type: batteryType, dgdTab } = context?.parent || {};

      if (dgdTab === DGDTab.DGD) return true;

      if (
        dgdTab === DGDTab.MSDS &&
        batteryType === BatteryType.DG_BATTERIES_IN_EQUIPMENT
      ) {
        return [Section.I, Section.II, Section.IA, Section.IB].includes(
          value as Section,
        );
      }
      return true;
    })
    .nullable(),

  battery_type: Yup.string()
    .test('batteryType-test', 'This field is required', (value, context) => {
      const { cargo_type: cargoType } = context?.parent || {};
      if (
        cargoType === CargoType.Batteries &&
        ![
          BatteryType.DG_BATTERIES_IN_EQUIPMENT,
          BatteryType.NON_RESTRICTED_BATTERIES_IN_EQUIPMENT,
        ].includes(value as BatteryType)
      ) {
        return false;
      }
      return true;
    })
    .nullable(),

  battery_type_variant: Yup.mixed()
    .test(
      'batteryTypeVariant-test',
      'This field is required',
      function (value, context) {
        const { battery_type: batteryType } = context?.parent || {};
        if (batteryType === BatteryType.NON_RESTRICTED_BATTERIES_IN_EQUIPMENT) {
          return [
            BatteryTypeVariant.LiIon_UN3481_PI967,
            BatteryTypeVariant.LiIon_UN3481_PI966,
            BatteryTypeVariant.LiMetal_UN3091_PI969,
            BatteryTypeVariant.LiMetal_UN3091_PI970,
          ].includes(value as BatteryTypeVariant);
        }
        return true;
      },
    )
    .nullable(),
  documents: Yup.array().of(
    Yup.object({
      original_filename: Yup.string().required(),

      document_type: Yup.mixed<DocumentType>()
        .oneOf(Object.values(DocumentType), 'Invalid document type')
        .nullable(),
    }),
  ),
}).required();

export const defaultCargoItem: Partial<CargoItem> = {
  quantity: 1,
  handling: HandlingOptions.Stackable,
  packing: [],
};

const defaultValues: Partial<RequestFormValues> = {
  cargo_type: CargoType.GeneralCargo,
  original_dimension_unit: DimensionUnit.in,
  original_weight_unit: WeightUnit.kg,
  cargo_details: 'Weight Per Piece',
  cargo_origin: {},
  cargo: [defaultCargoItem as any],
  dgdTab: DGDTab.DGD,
  // TODO: Make part of form
  flight: 'Any',
  service_type: ServiceType.General,
  aircraft_types: Object.values(AircraftType),
};

export const useRequestForm = (): UseRequestForm => {
  const { handleSuccess } = useSuccess();
  const { handleError } = useError();

  const [request, setRequest] = useSessionStorage<RequestFormValues>(
    'request',
    {} as RequestFormValues,
  );

  useEffect(() => {
    if (isEmpty(request)) setRequest(defaultValues as RequestFormValues);
    else reset(request);
    // should only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (document.location.hash.startsWith('#prefill:')) {
      const incomingRequest = JSON.parse(atob(document.location.hash.slice(9)));

      // Check to see if either version exists
      const rawDropoffBy =
        incomingRequest?.drop_off_by ?? incomingRequest?.dropoff_by;
      const dropoff_by = rawDropoffBy ? new Date(rawDropoffBy) : null;

      const modifiedRequest = {
        ...(incomingRequest || {}),
        dropoff_by: isNaN(dropoff_by?.getTime()) ? undefined : dropoff_by,
        cargo:
          (incomingRequest?.cargo ?? incomingRequest?.cargos)?.map((cargo) => ({
            // Zero is an okay fallback, as it will get caught in Yup's filtering
            ...(cargo || {}),
            weight: Number(cargo?.weight) || 0,
            height: Number(cargo?.height) || 0,
            width: Number(cargo?.width) || 0,
            length: Number(cargo?.length) || 0,
            quantity: Number(cargo?.quantity) || 0,
          })) || [],
        // This should minimize data discrepancies
        destination_airports: incomingRequest?.destination_airports?.filter(
          (airport) => airport?.length === 3,
        ),
        origin_airports: incomingRequest?.origin_airports?.filter(
          (airport) => airport?.length === 3,
        ),
      };

      // We can't account for every error, this causes it to fail gracefully.
      try {
        // type shenanigans have to happen due to yup changing things around
        const strippedRequest = validationSchema.cast(modifiedRequest, {
          stripUnknown: true,
        }) as any;

        setRequest(strippedRequest);
        // Since we need to reload, we can lie and say that it's importing. Lets people know that it's all good
        handleSuccess('Importing request...');
        setTimeout(() => window.location.reload(), 3000);
      } catch (error) {
        handleError(
          'Unable to auto-fill request. The Customer Success team has been notified and will be in touch with you soon.',
        );
      }
      document.location.hash = '';
    }
  });

  const { reset, getValues, setValue, ...methods } = useForm<RequestFormValues>(
    {
      mode: 'onSubmit',
      // TODO: fix typings
      resolver: yupResolver(validationSchema) as any,
      defaultValues,
    },
  );

  return { methods: { ...methods, getValues, setValue, reset } };
};
