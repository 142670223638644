import {
  Box,
  IconButton,
  RadioProps,
  Stack,
  Text,
  Tooltip,
  useRadioGroup,
} from '@chakra-ui/react';
import { Show } from '@pelicargo/ui';
import { stringToBoolean } from '@pelicargo/utils';
import { ReactNode, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { HiInformationCircle } from 'react-icons/hi2';

import { SimpleFieldVariant } from '../utils/types';
import { Radio } from './Radio';
import { SimpleField } from './SimpleField';

type Option = {
  label: string;
  value: string | boolean;
};

type SimpleSwitchProps = RadioProps & {
  name: string;
  label?: string;
  direction?: 'row' | 'column';
  tooltip?: string | ReactNode;
  options: Option[];
  variant?: 'inline' | 'outline';
  fieldVariant?: SimpleFieldVariant;
};

export const SimpleSwitch = ({
  name,
  label,
  options,
  tooltip,
  fieldVariant,
  variant,
  direction = 'row',
  ...rest
}: SimpleSwitchProps) => {
  const { setValue, watch } = useFormContext();
  const value = watch(name);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleOpenTooltip = () => setIsTooltipOpen(true);
  const handleCloseTooltip = () => setIsTooltipOpen(false);
  const handleToggleTooltip = () => setIsTooltipOpen((prev) => !prev);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    value,
    onChange: (nextValue: string) => {
      const parsedValue = stringToBoolean(nextValue) ?? nextValue;
      setValue(name, parsedValue as any);
    },
  });

  const renderRadio = (opt: Option) => {
    const radioProps = getRadioProps({ value: opt.value });
    return (
      <Radio
        key={String(opt.value)}
        variant={variant}
        w="full"
        isVertical={direction === 'column'}
        tabIndex={0}
        _focus={{
          outline: 'none',
          boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6)',
          borderColor: 'blue.500',
        }}
        {...radioProps}
      >
        <Text fontSize="md" fontWeight="semibold" whiteSpace="nowrap">
          {opt.label}
        </Text>
      </Radio>
    );
  };

  return (
    <SimpleField name={name} label={label} variant={fieldVariant}>
      <Stack
        direction={direction}
        h="full"
        w="full"
        {...rest}
        {...getRootProps()}
      >
        {options.map(renderRadio)}
      </Stack>
      <Show if={!!tooltip}>
        <Box position="absolute" right="1" top="1">
          <Tooltip
            hasArrow
            placement="bottom"
            label={tooltip}
            bg="gray.800"
            color="white"
            isOpen={isTooltipOpen}
          >
            <IconButton
              height="auto"
              minW="auto"
              aria-label="Toggle tooltip"
              variant="unstyled"
              icon={<HiInformationCircle />}
              onMouseOver={handleOpenTooltip}
              onMouseLeave={handleCloseTooltip}
              onClick={handleToggleTooltip}
            />
          </Tooltip>
        </Box>
      </Show>
    </SimpleField>
  );
};
