import { Button, useToast } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { OnboardingLayout } from '../../components/OnboardingLayout';
import { Page } from '../../components/Page';
import { SimpleForm } from '../../components/SimpleForm';
import { SimplePasswordInput } from '../../components/SimplePasswordInput';
import { supabaseClient } from '../../utils/supabaseClient';

type FormValues = {
  password: string;
  confirmPassword: string;
};

const validationSchema = z
  .object({
    password: z.string({ required_error: 'This field is required' }),
    confirmPassword: z.string().nullish(),
  })
  .refine((input) => input.password === input.confirmPassword, {
    message: 'Passwords must match',
  });
export const ResetPassword = () => {
  const navigate = useNavigate();
  const toast = useToast({ position: 'bottom', size: 'sm', duration: 3000 });

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: zodResolver(validationSchema),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (values: FormValues) => {
    try {
      setIsSubmitting(true);
      const { error } = await supabaseClient.auth.updateUser({
        password: values.password,
      });
      if (error) throw Error(error?.message);

      navigate('/');
    } catch (error) {
      toast({
        title: 'Error',
        description: error?.message,
        status: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Page title="Reset password" showHeader={false} showFooter={false}>
      <OnboardingLayout heading="Reset Password">
        <SimpleForm onSubmit={handleSubmit} {...methods}>
          <SimplePasswordInput
            name="password"
            label="Password"
            placeholder="Password"
            variant="outline"
          />
          <SimplePasswordInput
            name="confirmPassword"
            placeholder="Confirm password"
            label="Confirm password"
            variant="outline"
          />

          <Button
            colorScheme="primary"
            size="lg"
            alignSelf="flex-end"
            type="submit"
            isLoading={isSubmitting}
          >
            Submit
          </Button>
        </SimpleForm>
      </OnboardingLayout>
    </Page>
  );
};
