import { useToast } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import {
  createWSClient,
  httpLink,
  splitLink,
  TRPCClientError,
  wsLink,
} from '@trpc/client';
import { ReactNode, useEffect, useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import { ENV } from '../config/env';
import { trpc } from '../config/trpc';
import { useTargetAccount } from '../hooks/useTargetAccount';
import { supabaseClient } from '../utils/supabaseClient';

type Props = {
  children: ReactNode;
};

const buildUrl = (jwtToken?: string, targetAccountId?: number) => {
  const url = new URL(ENV.WS_API_URL);
  if (jwtToken) url.searchParams.append('jwt', jwtToken);
  if (targetAccountId) {
    const id = String(targetAccountId);
    url.searchParams.append('targetAccountId', id);
  }

  return url.toString();
};

export const TRPC = ({ children }: Props) => {
  const errorToast = useToast({ status: 'error' });
  const [jwtToken] = useLocalStorage('accessToken', '');
  const { targetAccount } = useTargetAccount();

  const queryClient = useMemo(() => {
    return new QueryClient({
      queryCache: new QueryCache({
        onError: async (error) => {
          Sentry.captureException(error);

          const isTrpcError = error instanceof TRPCClientError;
          if (!isTrpcError) return console.log(error);

          const { code } = error?.data || {};
          if (error?.message === 'LOGOUT') {
            Sentry.setUser(null);
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = '/login';
            return;
          }
          const isUnauthorized = code === 'UNAUTHORIZED';
          const isForbidden = code === 'FORBIDDEN';

          if (isUnauthorized) return supabaseClient.auth.refreshSession();
          if (isForbidden) {
            errorToast({
              title: 'Forbidden',
              description: `You don't have access to this.`,
            });
          }
        },
      }),
    });
  }, [errorToast]);

  const wsClient = useMemo(() => {
    return createWSClient({ url: buildUrl(jwtToken, targetAccount?.id) });
  }, [jwtToken, targetAccount?.id]);

  const trpcClient = useMemo(() => {
    return trpc.createClient({
      links: [
        splitLink({
          condition: (op) => op.type === 'subscription',
          true: wsLink({ client: wsClient }),
          false: httpLink({
            url: `${ENV.API_BASE_URL}trpc`,
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              'x-target-account-id': String(targetAccount?.id || ''),
            },
          }),
        }),
      ],
      transformer: undefined,
    });
  }, [wsClient, jwtToken, targetAccount?.id]);

  useEffect(() => {
    return () => wsClient.close();
  }, [wsClient]);

  if (!trpcClient) return null;

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
};
