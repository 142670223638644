import { Icon, Stack, StackProps, Text, Tooltip } from '@chakra-ui/react';
import { Show } from '@pelicargo/ui';
import { ReactNode, useMemo } from 'react';
import { HiInformationCircle } from 'react-icons/hi2';

type Props = StackProps & {
  label: string;
  value?: string | number;
  tooltip?: ReactNode;
  children?: ReactNode;
};

export const LabeledValue = ({
  label,
  value,
  tooltip,
  children,
  ...rest
}: Props) => {
  const displayLabel = useMemo(() => {
    if (tooltip) {
      return (
        <Tooltip
          label={tooltip}
          fontSize="sm"
          bg="gray.800"
          color="white"
          p="2"
          w="full"
        >
          <Stack direction="row">
            <Text textStyle="label">{label}</Text>
            <Icon as={HiInformationCircle} fontSize="12px" color="#000" />
          </Stack>
        </Tooltip>
      );
    }

    return <Text textStyle="label">{label}</Text>;
  }, [label, tooltip]);

  return (
    <Stack direction="column" spacing={1} w="auto" {...rest}>
      <Stack direction="row" align="center" spacing={0} w="auto">
        {displayLabel}
      </Stack>
      <Show if={!!value}>
        <Text textStyle="bodySmallRegular" w="auto">
          {value}
        </Text>
      </Show>
      <Show if={!!children}>{children}</Show>
    </Stack>
  );
};
