import {
  Card,
  CardBody,
  Center,
  Flex,
  Heading,
  Hide,
  Image,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Show } from '@pelicargo/ui';
import { ReactNode } from 'react';
import { HiCheck } from 'react-icons/hi2';

import OnboardingPropImage from '../../assets/onboarding-prop.png';
import { CargoPlane } from '../CargoPlane';
import { Logo } from '../Logo';

type Props = {
  heading: string;
  subHeading?: string;
  children: ReactNode;
};

export const AuthLayout = ({ heading, subHeading, children }: Props) => {
  return (
    <Flex
      minH={{ base: 'auto', md: '100vh' }}
      position="relative"
      h="full"
      w="full"
    >
      <Flex w="full" maxW="full" bg="gray.700">
        <Hide below="lg">
          <Flex
            flex="1"
            direction="column"
            align="center"
            justify="center"
            h="full"
            w="full"
          >
            <Stack spacing="12" p="16">
              <Stack spacing="3">
                <Heading variant="h2" color="whiteAlpha.900">
                  Experience the Pelicargo Difference
                </Heading>
                <Text textStyle="body" color="whiteAlpha.900">
                  Use Pelicargo's intelligent sourcing technology to win more
                  business.
                </Text>
              </Stack>
              <Center>
                <Image src={OnboardingPropImage} maxW="370px" />
              </Center>

              <List spacing="4" w="full">
                <ListItem textStyle="bodySmall" color="whiteAlpha.900">
                  <ListIcon as={HiCheck} color="green.200" />
                  Secure the best rates faster and easier than ever before
                </ListItem>
                <ListItem textStyle="bodySmall" color="whiteAlpha.900">
                  <ListIcon as={HiCheck} color="green.200" />
                  Gain access to powerful tools to navigate air cargo schedules
                  & regulations
                </ListItem>
                <ListItem textStyle="bodySmall" color="whiteAlpha.900">
                  <ListIcon as={HiCheck} color="green.200" />
                  Ship any cargo type and size, with any carrier, anywhere!
                </ListItem>
              </List>
            </Stack>
          </Flex>
        </Hide>
        <Stack
          justify="center"
          align="center"
          bg="bg.default"
          spacing="16"
          h={{ base: '100vh', lg: 'full' }}
          position="relative"
          flex="2"
        >
          <Stack p="4" py="8" align="center" spacing="8" w="full" zIndex="999">
            <Logo desktopOnly />
            <Card h="auto" w="full" maxW="600px" bgColor="bg.surface">
              <CardBody p="6">
                <Stack spacing="6">
                  <Stack spacing="1">
                    <Heading as="h1" variant="h3">
                      {heading}
                    </Heading>
                    <Show if={!!subHeading}>
                      <Text textStyle="bodySmall">{subHeading}</Text>
                    </Show>
                  </Stack>
                  {children}
                </Stack>
              </CardBody>
            </Card>
          </Stack>
          <CargoPlane />
        </Stack>
      </Flex>
    </Flex>
  );
};
