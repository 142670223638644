import { Grid, GridItem } from '@chakra-ui/react';
import { DimensionUnit, WeightUnit } from '@pelicargo/types';
import { handlingOptions } from '@pelicargo/utils';
import { useFormContext } from 'react-hook-form';

import { SimpleDimensionsInput } from './SimpleDimensionsInput';
import { SimpleHandlingOptions } from './SimpleHandlingOptions';
import { SimpleInput } from './SimpleInput';
import { SimpleQuantityInput } from './SimpleQuantityInput';

type Props = {
  prefix: string;
  weightUnit: WeightUnit;
  dimensionUnit: DimensionUnit;
};

export const CargoListItem = ({ prefix, weightUnit, dimensionUnit }: Props) => {
  const { watch } = useFormContext();
  const cargoDetails = watch('cargo_details');
  const isTotalWeight = cargoDetails === 'Gross Weight';

  return (
    <Grid
      templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(25, 1fr)' }}
      gap={4}
      alignItems="baseline"
    >
      <GridItem colSpan={{ base: 1, lg: 17 }} alignItems="baseline">
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(34, 1fr)' }}
          gap={4}
          alignItems="baseline"
        >
          <GridItem colSpan={{ base: 1, lg: 8 }}>
            <SimpleQuantityInput
              name={`${prefix}.quantity`}
              label="# Pieces"
              min={1}
            />
          </GridItem>
          <GridItem colSpan={{ base: 1, lg: 16 }}>
            <SimpleDimensionsInput
              dimensionUnit={dimensionUnit}
              prefix={prefix}
            />
          </GridItem>
          <GridItem colSpan={{ base: 1, lg: 10 }}>
            <SimpleInput
              name={`${prefix}.weight`}
              label={`Weight (${weightUnit})`}
              placeholder="Weight"
              type="number"
              inputMode="numeric"
              isDisabled={isTotalWeight}
              inputProps={{
                autoComplete: 'off',
              }}
            />
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem colSpan={{ base: 1, lg: 8 }}>
        <SimpleHandlingOptions
          options={handlingOptions}
          handlingName={`${prefix}.handling`}
          packingName={`${prefix}.packing`}
          label="Handling | Packing"
        />
      </GridItem>
    </Grid>
  );
};
