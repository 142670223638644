import {
  Box,
  Checkbox,
  CheckboxGroup,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { SelectOption } from '@pelicargo/types';
import { HANDLING_OPTIONS, PACKING_OPTIONS, Show } from '@pelicargo/ui';
import { useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { SimpleField } from './SimpleField';

type Props = {
  handlingName: string;
  packingName: string;
  label: string;
  options: SelectOption[];
};

const options = [...HANDLING_OPTIONS, ...PACKING_OPTIONS];

export const SimpleHandlingOptions = ({
  handlingName,
  packingName,
  label,
}: Props) => {
  const handling = useWatch({ name: handlingName });
  const packing = useWatch({ name: packingName });
  const { setValue } = useFormContext();

  const onRadioChange = useCallback(
    (nextValue: string) => {
      setValue(handlingName, nextValue);
    },
    [handlingName, setValue],
  );

  const onCheckboxChange = useCallback(
    (nextValue: string[]) => {
      setValue(packingName, nextValue);
    },
    [packingName, setValue],
  );

  const selectedOptions = useMemo(() => {
    return [handling, ...(packing || [])];
  }, [handling, packing]);

  const renderHandlingOption = useCallback(({ label, value, icon }) => {
    return (
      <Radio key={value} value={value} size="lg">
        <Stack direction="row" align="center">
          <Icon as={icon} w="20px" h="20px" />
          <Text>{label}</Text>
        </Stack>
      </Radio>
    );
  }, []);

  const renderPackingOption = useCallback(({ label, value, icon }) => {
    return (
      <Checkbox key={value} value={value} size="lg">
        <Stack direction="row" align="center">
          <Icon as={icon} w="20px" h="20px" />
          <Text>{label}</Text>
        </Stack>
      </Checkbox>
    );
  }, []);

  const renderSelectedOption = (option: string | number) => {
    const selected = options.find((opt) => opt.value === option);

    return (
      <Box key={selected?.value} h="full">
        <Tooltip label={selected?.label}>
          <Stack direction="row" align="center">
            <Icon as={selected?.icon} w="21px" h="21px" />
            <Show if={selectedOptions?.length === 1}>
              <Text fontSize="sm">{selected?.label}</Text>
            </Show>
          </Stack>
        </Tooltip>
      </Box>
    );
  };

  return (
    <Popover matchWidth>
      <PopoverTrigger>
        <SimpleField label={label}>
          <Stack
            direction="row"
            alignItems="center"
            tabIndex={0}
            _focus={{
              outline: 'none',
              boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6)',
              borderColor: 'blue.500',
            }}
          >
            <Show if={!selectedOptions?.length}>
              <Text fontSize="md" color="gray.400">
                None selected
              </Text>
            </Show>
            {selectedOptions?.map(renderSelectedOption)}
          </Stack>
        </SimpleField>
      </PopoverTrigger>
      <PopoverContent p="2" w="full">
        <Stack spacing="4">
          <Stack>
            <Text textStyle="caption">Handling</Text>
            <RadioGroup
              colorScheme="blue"
              value={handling}
              onChange={onRadioChange}
            >
              <Stack spacing="2">
                {HANDLING_OPTIONS.map(renderHandlingOption)}
              </Stack>
            </RadioGroup>
          </Stack>
          <Stack>
            <Text textStyle="caption">Packing</Text>
            <CheckboxGroup
              colorScheme="blue"
              value={packing}
              onChange={onCheckboxChange}
            >
              {PACKING_OPTIONS.map(renderPackingOption)}
            </CheckboxGroup>
          </Stack>
        </Stack>
      </PopoverContent>
    </Popover>
  );
};
