import { Input, InputProps } from '@chakra-ui/react';
import { ChangeEvent, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { SimpleField } from './SimpleField';

type Props = InputProps & {
  name: string;
  label: string;
  maxLength?: number;
  allowDecimals?: boolean;
};

export const SimpleNumberInput = ({
  name,
  label,
  maxLength = 10,
  allowDecimals = false,
  ...rest
}: Props) => {
  const { setValue } = useFormContext();
  const value = useWatch({ name });

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const inputVal = event.target.value;

      // Counting only digits, ignoring the decimal point
      const digitCount = inputVal.replace('.', '').length;

      // If the length of digits is greater than maxLength, return without updating the state
      if (maxLength && digitCount > maxLength) return;

      // If decimals are not allowed, make sure input is an integer
      if (!allowDecimals && !/^\d*$/.test(inputVal)) return;

      // If decimals are allowed, make sure input is a valid number with only one decimal point
      if (allowDecimals && !/^\d*\.?\d*$/.test(inputVal)) return;

      setValue(name, inputVal);
    },
    [allowDecimals, maxLength, name, setValue],
  );

  return (
    <SimpleField name={name} label={label}>
      <Input
        type="text"
        inputMode="numeric"
        variant="unstyled"
        fontSize="md"
        value={value}
        onChange={handleChange}
        _placeholder={{ color: 'text.placeholder' }}
        {...rest}
      />
    </SimpleField>
  );
};
