import {
  Box,
  HStack,
  IconButton,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { addMonths, format } from 'date-fns';
import * as R from 'ramda';
import { useCallback, useState } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

import { Calendar } from './Calendar';
import { DatePickerStyleConfig, defaultDatePickerStyle } from './type';
import { DateRulesFn } from './utils/dayListGenerator';
import { Week } from './Week';

type Props = {
  selectedDate: Date;
  datePickerStyle?: Partial<DatePickerStyleConfig>;
  onSetDate?: (date: Date) => void;
  onDayTypeRulesFn: DateRulesFn;
};

export const DateTimePicker = ({
  selectedDate,
  datePickerStyle,
  onSetDate,
  onDayTypeRulesFn,
}: Props) => {
  const [controlDate, setControlDate] = useState(new Date(selectedDate));

  const handlePrevMonth = useCallback(() => {
    const nextControlDate = addMonths(controlDate, -1);
    setControlDate(nextControlDate);
  }, [controlDate]);

  const handleNextMonth = useCallback(() => {
    const nextControlDate = addMonths(controlDate, 1);
    setControlDate(nextControlDate);
  }, [controlDate]);

  const mergedDatePickerStyle: DatePickerStyleConfig = R.mergeDeepRight(
    defaultDatePickerStyle,
    datePickerStyle,
  );

  return (
    <Box bgColor={useColorModeValue('white', 'gray.700')} borderRadius="lg">
      <Stack direction={{ base: 'column', lg: 'row' }} mt="4">
        <Stack w="full" flex="2">
          <HStack justify="space-between">
            <IconButton
              variant="ghost"
              aria-label="prev month"
              icon={<HiChevronLeft />}
              onClick={handlePrevMonth}
            />
            <Text
              textAlign="center"
              color={useColorModeValue('black', 'white')}
              fontWeight="semibold"
            >
              {format(controlDate, 'MMMM yyyy')}
            </Text>
            <IconButton
              variant="ghost"
              aria-label="next month"
              icon={<HiChevronRight />}
              onClick={handleNextMonth}
            />
          </HStack>
          <Week p={2} weekStyleConfig={mergedDatePickerStyle.weekStyle} />
          <Calendar
            year={controlDate.getFullYear()}
            month={controlDate.getMonth()}
            onSetDate={onSetDate}
            onGetDayTypeRulesFn={onDayTypeRulesFn}
            dayStyleConfig={mergedDatePickerStyle.dayStyle}
            p="2"
          />
        </Stack>
      </Stack>
    </Box>
  );
};
