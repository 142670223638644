import {
  Button,
  Card,
  CardBody,
  Divider,
  Heading,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Cargo, CargoType, DimensionUnit, WeightUnit } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import {
  ensureDimensionUnit,
  getHandlingAndPacking,
  getPrettyCargoType,
  getPrettyDimensionUnit,
  kgToLb,
  prettyNumber,
} from '@pelicargo/utils';
import { useState } from 'react';

import { LabeledValue } from '../LabeledValue';

type Props = {
  cargoType: CargoType;
  isKnownShipper: boolean;
  commodity: string;
  volumetricWeight: number;
  grossWeight: number;
  tenderDate: string;
  cargo?: Cargo[];
  dimensionUnit: DimensionUnit;
  weightUnit: WeightUnit;
};

export const RequestDetailsCard = ({
  cargoType,
  isKnownShipper,
  commodity,
  grossWeight,
  weightUnit,
  volumetricWeight,
  tenderDate,
  cargo = [],
  dimensionUnit,
}: Props) => {
  const [showCargo, setShowCargo] = useState(false);

  const handleToggle = () => {
    setShowCargo(!showCargo);
  };

  const isInLbs = weightUnit === WeightUnit.lb;

  const renderCargoItem = (item: Cargo) => {
    const length = ensureDimensionUnit(item?.length, dimensionUnit);
    const width = ensureDimensionUnit(item?.width, dimensionUnit);
    const height = ensureDimensionUnit(item?.height, dimensionUnit);

    return (
      <Stack key={item?.id} direction="row" align="center" spacing="2">
        <Text textStyle="captionRegular">{item?.quantity} Pcs</Text>
        <Divider
          orientation="vertical"
          h="4"
          borderWidth="1px"
          borderColor="border.default"
        />
        <Text textStyle="captionRegular">
          {prettyNumber(length)}x{prettyNumber(width)}x{prettyNumber(height)}{' '}
          {getPrettyDimensionUnit(dimensionUnit)}
        </Text>
        <Divider
          orientation="vertical"
          h="4"
          borderWidth="1px"
          borderColor="border.default"
        />
        <Show if={isInLbs}>
          <Tooltip
            label={`This weight has been converted from ${prettyNumber(kgToLb(item?.weight))} LBs to ${prettyNumber(item?.weight)} KGs`}
          >
            <Text textStyle="captionRegular">
              {prettyNumber(item?.weight)} kg
            </Text>
          </Tooltip>
        </Show>
        <Show if={!isInLbs}>
          <Text textStyle="captionRegular">
            {prettyNumber(item?.weight)} kg
          </Text>
        </Show>
        <Divider
          orientation="vertical"
          h="4"
          borderWidth="1px"
          borderColor="border.default"
        />
        <Text textStyle="captionRegular">{getHandlingAndPacking(item)}</Text>
      </Stack>
    );
  };

  return (
    <Card h="auto" px="4" py="3">
      <CardBody p="0">
        <Stack spacing="4">
          <Heading variant="h5">Request Details</Heading>
          <Stack direction="row" justify="flex-start" wrap="wrap" spacing="6">
            <LabeledValue
              label="Cargo Type"
              value={getPrettyCargoType(cargoType)}
            />
            <LabeledValue label="Commodity" value={commodity} />
            <LabeledValue
              label="Weight"
              value={`Gross ${prettyNumber(grossWeight)} kg | Vol ${prettyNumber(volumetricWeight)} kg`}
            />
            <LabeledValue
              label="Known Shipper"
              value={isKnownShipper ? 'Yes' : 'No'}
            />
            <LabeledValue label="Tender" value={tenderDate} />
          </Stack>
          <Stack>
            <Text textStyle="label">Cargo</Text>
            {cargo
              ?.slice(0, showCargo ? cargo?.length : 3)
              ?.map(renderCargoItem)}
            <Show if={cargo?.length > 3}>
              <Button variant="outline" onClick={handleToggle}>
                {showCargo ? 'View less' : 'View more'}
              </Button>
            </Show>
          </Stack>
        </Stack>
      </CardBody>
    </Card>
  );
};
