import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  'label',
  'value',
]);

const variants = {
  primary: {
    label: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    value: {
      fontWeight: 'normal',
    },
  },
  inverted: {
    label: {
      textTransform: 'uppercase',
      fontWeight: 'normal',
    },
    value: {
      fontWeight: 'bold',
    },
  },
};

const sizes = {
  sm: {
    label: {
      fontSize: 'xs',
    },
    value: {
      fontSize: 'md',
    },
  },
  md: {
    label: {
      fontSize: 'sm',
    },
    value: {
      fontSize: 'xl',
    },
  },
  lg: {
    label: {
      fontSize: 'md',
    },
    value: {
      fontSize: 'xl',
    },
  },
};

export default defineMultiStyleConfig({
  variants,
  sizes,
});
