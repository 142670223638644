import {
  Button,
  Icon,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import { ElementType, ReactNode } from 'react';
import { HiChevronDown } from 'react-icons/hi';

type Props = {
  label: string;
  icon?: ElementType;
  hasValue?: boolean;
};

export const FilterPopoverButton = ({ label, icon, hasValue }: Props) => {
  return (
    <PopoverTrigger>
      <Button
        bgColor="bg.surface"
        borderColor={hasValue ? 'border.focused' : 'border.light'}
        borderWidth="1px"
        borderRadius="8"
        _hover={{
          borderColor: 'border.hover',
          bgColor: 'bg.surface',
        }}
        _active={{
          borderColor: 'border.focused',
          bgColor: 'bg.surface',
        }}
        _focus={{
          borderColor: 'border.focused',
          bgColor: 'bg.surface',
        }}
        leftIcon={icon ? <Icon as={icon} /> : null}
        rightIcon={<HiChevronDown />}
      >
        {label}
      </Button>
    </PopoverTrigger>
  );
};

type FilterPopoverContentProps = {
  header?: string;
  children?: ReactNode;
};

export const FilterPopoverContent = ({
  header,
  children,
}: FilterPopoverContentProps) => {
  return (
    <PopoverContent
      _focus={{ shadow: 'none', outline: 0 }}
      _focusVisible={{ shadow: 'outline' }}
      maxH="300px"
      overflowY="scroll"
    >
      {header && <PopoverHeader srOnly>{header}</PopoverHeader>}
      <PopoverBody px="3" py="1.5">
        {children}
      </PopoverBody>
    </PopoverContent>
  );
};
