import { Circle, Divider, Heading, Stack } from '@chakra-ui/react';

type Props = {
  from: string;
  to: string;
};

export const SingleFlightPath = ({ from, to }: Props) => {
  return (
    <Stack direction="row" align="center" w="auto" spacing="4">
      <Heading variant="h6" minW="50px">
        {from}
      </Heading>

      <Stack direction="row" align="center" spacing="-1">
        <Circle
          size="8px"
          bg="white"
          border="2px"
          borderColor="border.default"
          fontSize="sm"
        />
        <Stack
          align="center"
          minW={{ base: '4', lg: '20' }}
          spacing="0"
          w="full"
          position="relative"
        >
          <Divider borderWidth="2px" borderColor="border.default" />
        </Stack>
        <Circle
          size="8px"
          bg="white"
          border="2px"
          borderColor="border.default"
          fontSize="sm"
        />
      </Stack>
      <Heading variant="h6" minW="50px">
        {to}
      </Heading>
    </Stack>
  );
};
