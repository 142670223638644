import { Card, CardBody, Heading, Stack, Text } from '@chakra-ui/react';
import { Show } from '@pelicargo/ui';
import { ReactNode } from 'react';

import { CargoPlane } from './CargoPlane';
import { Logo } from './Logo';

type Props = {
  heading: string;
  subHeading?: string;
  step?: number;
  children: ReactNode;
};

export const OnboardingLayout = ({
  heading,
  subHeading,
  step,
  children,
}: Props) => {
  return (
    <Stack bg="bg.default" spacing="16" minH="100vh" position="relative">
      <Stack
        p="4"
        py="8"
        align="center"
        spacing="8"
        w="full"
        zIndex="999"
        pt={{ base: '16', lg: '32' }}
      >
        <Logo desktopOnly />
        <Card h="auto" maxW="760px" w="full">
          <CardBody p="6">
            <Stack spacing="6">
              <Stack spacing="1">
                <Show if={!!step}>
                  <Text textStyle="bodySmall" fontWeight="600" color="gray.600">
                    Step {step} of 3
                  </Text>
                </Show>
                <Heading as="h1" variant="h3">
                  {heading}
                </Heading>
                <Show if={!!subHeading}>
                  <Text textStyle="bodySmall">{subHeading}</Text>
                </Show>
              </Stack>
              {children}
            </Stack>
          </CardBody>
        </Card>
      </Stack>
      <CargoPlane />
    </Stack>
  );
};
