import { ChakraProvider } from '@chakra-ui/react';
import { css, Global } from '@emotion/react';
import { OnboardingStep } from '@pelicargo/types';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { hotjar } from 'react-hotjar';
import {
  BrowserRouter,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useLocalStorage, useSessionStorage } from 'usehooks-ts';
import { v4 } from 'uuid';

import { TRPC } from './components/TRPC';
import { ENV } from './config/env';
import { theme } from './config/theme';
import { trpc } from './config/trpc';
import { AuthProvider as PelicargoAuthProvider } from './context/AuthContext';
import { useAuth } from './hooks/auth/useAuth';
import { useTargetAccount } from './hooks/useTargetAccount';
import { Router } from './Router';
import { MixpanelEvent, trackEvent } from './utils/mixpanel';

const APP_ENV = ENV.APP_ENV;
const HJ_ID = Number(ENV.HJ_ID) || -1;
const HJ_SV = Number(ENV.HJ_SV) || -1;

Sentry.init({
  dsn: ENV.SENTRY_DSN,
  environment: APP_ENV,
  enabled: APP_ENV === 'stg' || APP_ENV === 'prd',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['http://localhost:3000'],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Hide LOGOUT messages. "The operation was aborted" can also be caused by LOGOUT
  ignoreErrors: [/.*LOGOUT/, /.*The operation was aborted/i],
});

mixpanel.init(ENV.MIXPANEL_TOKEN);

const GlobalStyles = css`
  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  html,
  body {
    padding: 0px;
    margin: 0px;
    height: 100%;
    width: 100%;
  }
`;

const onboardingStepRoutes = {
  [OnboardingStep.CONFIRM]: '/register/confirm',
  [OnboardingStep.PERSONAL]: '/register/personal',
  [OnboardingStep.COMPANY]: '/register/company',
  [OnboardingStep.COMPANY_SELECT]: '/register/company/select',
  [OnboardingStep.SIGNATURE]: '/register/signature',
  [OnboardingStep.INVITE]: '/register/invite',
};

const App = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const [documentSessionId, setDocumentSessionId] = useSessionStorage(
    'documentSessionId',
    '',
  );
  const [, setOnboardingEmail] = useLocalStorage('onboardingEmail', null);
  const { targetAccount } = useTargetAccount();

  const location = useLocation();
  const [quoteId, setQuoteId] = useState(null);
  const { data } = trpc.getRequestIdFromQuote.useQuery(
    { id: quoteId },
    { enabled: !!quoteId },
  );

  useEffect(() => {
    if (data) {
      navigate(`/requests/${data.quote.request_id}/quotes/${quoteId}`);
    }
  }, [data, navigate, quoteId]);

  useEffect(() => {
    const listingPattern = /^\/quote-listing\/(\d+)$/;
    const confirmationPattern = /^\/quote-confirmation\/(\d+)$/;

    const listingMatch = location.pathname.match(listingPattern);
    const confirmationMatch = location.pathname.match(confirmationPattern);

    if (listingMatch) {
      const requestId = listingMatch[1];
      navigate(`/requests/${requestId}`);
    }

    if (confirmationMatch) {
      const quoteId = confirmationMatch[1];
      setQuoteId(Number(quoteId));
    }
  }, [location.pathname]);

  useEffect(() => {
    const canInitHotjar = HJ_ID && HJ_SV && APP_ENV === 'prd';
    if (canInitHotjar) hotjar.initialize({ id: HJ_ID, sv: HJ_SV });

    trackEvent(MixpanelEvent.SessionStart);
  }, []);

  useEffect(() => {
    if (!documentSessionId) {
      setDocumentSessionId(v4());
    }
  }, [documentSessionId, setDocumentSessionId]);

  useEffect(() => {
    const emailFromParams = searchParams.get('email');
    if (emailFromParams) {
      setOnboardingEmail(emailFromParams);
    }
  }, [searchParams, setOnboardingEmail]);

  useEffect(() => {
    const onboardingStep = user?.onboarding_step;

    if (
      user?.onboarding_step === OnboardingStep.COMPLETE &&
      location.pathname.includes('register')
    ) {
      navigate('/');
      return;
    }

    if (
      user &&
      user?.onboarding_step === OnboardingStep.COMPLETE &&
      targetAccount &&
      targetAccount?.is_iata_number_verified === false
    ) {
      return navigate('/verification-pending');
    }

    if (
      user &&
      user?.onboarding_step === OnboardingStep.COMPLETE &&
      targetAccount &&
      targetAccount?.is_iata_number_verified &&
      location.pathname.includes('verification-pending')
    ) {
      return navigate('/');
    }

    if (!user || user?.onboarding_step === OnboardingStep.COMPLETE) {
      return;
    }

    if (onboardingStep && onboardingStep !== OnboardingStep.COMPLETE) {
      const nextStep = onboardingStepRoutes?.[onboardingStep];
      // if user is on confirm page, don't redirect to confirm page
      if (
        nextStep === '/register/confirm' &&
        location.pathname.includes('confirm')
      ) {
        return;
      }
      if (nextStep) {
        navigate(nextStep);
      }
    }
  }, [location.pathname, navigate, targetAccount, user]);

  return (
    <HelmetProvider>
      <Router />
    </HelmetProvider>
  );
};

createRoot(document.getElementById('root') as HTMLElement).render(
  <ChakraProvider resetCSS theme={theme}>
    <Global styles={GlobalStyles} />
    <BrowserRouter>
      <TRPC>
        <PelicargoAuthProvider>
          <App />
        </PelicargoAuthProvider>
      </TRPC>
    </BrowserRouter>
  </ChakraProvider>,
);
