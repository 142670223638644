import {
  Button,
  Card,
  CardBody,
  Center,
  Flex,
  IconButton,
  Spinner,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { OnboardingStep } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import { useCallback, useState } from 'react';
import { HiChevronRight } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';

import { OnboardingLayout } from '../../components/OnboardingLayout';
import { Page } from '../../components/Page';
import { trpc } from '../../config/trpc';
import { useOnboarding } from '../../hooks/onboarding/useOnboarding';
import { useScreenView } from '../../hooks/useScreenView';
import { API } from '../../utils/apiTypes';
import { MixpanelEvent, trackEvent } from '../../utils/mixpanel';

export const RegisterSelectCompany = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { setOnboardingStep } = useOnboarding();
  useScreenView(MixpanelEvent.CompanySelectView);

  const { data, isLoading } = trpc.getAccountInvites.useQuery();
  const { mutateAsync: acceptAccountInvite } =
    trpc.acceptAccountInvite.useMutation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSelectCompany = useCallback(
    (accountInvite: API.AccountInvite) => async () => {
      try {
        trackEvent(MixpanelEvent.CompanySelectSubmit);
        if (isSubmitting) {
          return;
        }
        setIsSubmitting(true);
        await acceptAccountInvite({ id: accountInvite?.id });

        toast({
          title: 'Company selected!',
          description: 'You have successfully selected your company.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        setIsSubmitting(false);
        navigate('/');
      } catch (error) {
        setIsSubmitting(false);
        toast({
          title: 'Invite could not be accepted',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    },
    [acceptAccountInvite, isSubmitting, navigate, toast],
  );

  const handleNewCompany = async () => {
    trackEvent(MixpanelEvent.CompanySelectNewCompany);
    await setOnboardingStep(OnboardingStep.COMPANY);
    navigate('/');
  };

  const renderAccountInvites = (accountInvite: API.AccountInvite) => {
    const account = accountInvite.account;

    return (
      <Card
        key={accountInvite.id}
        cursor="pointer"
        shadow="none"
        _hover={{ bg: 'gray.50' }}
        onClick={handleSelectCompany(accountInvite)}
      >
        <CardBody>
          <Stack direction="row" align="center" justify="space-between">
            <Flex />
            <Stack spacing="0">
              <Text fontWeight="bold">{account?.name}</Text>
              <Show if={!!account?.iata_number}>
                <Text textStyle="body">{account?.iata_number}</Text>
              </Show>
            </Stack>
            <IconButton
              icon={<HiChevronRight />}
              aria-label="Select Company Button"
              variant="unstyled"
              color="orange.500"
              colorScheme="primary"
              size="lg"
              isLoading={isSubmitting}
            />
          </Stack>
        </CardBody>
      </Card>
    );
  };

  return (
    <Page title="Select Company" showHeader={false} showFooter={false}>
      <OnboardingLayout
        heading="Welcome to Pelicargo!"
        subHeading="Please confirm the primary company you're associated with."
      >
        <Show if={isLoading}>
          <Center>
            <Spinner />
          </Center>
        </Show>
        <Show if={data?.length > 0}>
          <Stack spacing="5">{data?.map(renderAccountInvites)}</Stack>
        </Show>
        <Stack align="center">
          <Text textStyle="body">Don't see your company?</Text>
          <Button
            variant="link"
            colorScheme="primary"
            onClick={handleNewCompany}
          >
            Register new company
          </Button>
        </Stack>
      </OnboardingLayout>
    </Page>
  );
};
