import {
  Box,
  Card,
  CardBody,
  CardProps,
  Grid,
  GridItem,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import { PerUnitType } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import {
  allInVariableRate,
  prettyCurrency,
  prettyNumber,
} from '@pelicargo/utils';
import { Fragment, useCallback, useMemo } from 'react';

import { API } from '../../utils/api-typings';

type LineItem = API.RequestQuote['quote_variants'][0]['line_items'][0];

type Props = CardProps & {
  cardHeading?: string;
  cardSubheading?: string;
  lineItems?: LineItem[];
  cw?: number;
  validUntil?: string;
  showBorder?: boolean;
};

export const PriceCard = ({
  cardHeading = '',
  cardSubheading,
  lineItems,
  validUntil,
  showBorder,
  cw,
  ...rest
}: Props) => {
  const total = useMemo(() => {
    return lineItems
      ?.map((item) => Number(item?.cost))
      .reduce((prev: number, curr: number) => prev + curr, 0);
  }, [lineItems]);

  const renderLineItem = useCallback((item: LineItem) => {
    const isPercentage = item?.per_unit_type === PerUnitType.PERCENTAGE;

    const formattedPerUnitValue = isPercentage
      ? ''
      : `${prettyCurrency(item?.per_unit_value)}/kg`;
    const perUnitValue = item?.per_unit_value ? formattedPerUnitValue : '';

    const displayChargeableWeight =
      !!item?.per_unit_value && item?.per_unit_type !== PerUnitType.PERCENTAGE;
    const formattedChargeableWeight =
      item?.chargeable_weight && displayChargeableWeight
        ? `${prettyNumber(item.chargeable_weight)} kg`
        : '';

    return (
      <Fragment key={item?.id}>
        <GridItem>
          <Text textStyle="bodySmallRegular">{item?.label}</Text>
        </GridItem>
        <GridItem textAlign="right">
          <Text textStyle="bodySmallRegular">{formattedChargeableWeight}</Text>
        </GridItem>
        <GridItem textAlign="right">
          <Text textStyle="bodySmallRegular">{perUnitValue}</Text>
        </GridItem>
        <GridItem textAlign="right">
          <Text textStyle="bodySmallRegular">
            {item.cost ? `${prettyCurrency(item.cost)}` : ''}
          </Text>
        </GridItem>
      </Fragment>
    );
  }, []);

  const allInVariableRateCalc = useMemo(() => {
    const total = allInVariableRate(lineItems, cw);
    return prettyCurrency(total);
  }, [cw, lineItems]);

  return (
    <Card
      h="initial"
      borderColor={showBorder ? 'border.default' : 'transparent'}
      {...rest}
    >
      <CardBody p="0">
        <Box w="full">
          <VStack align="start" spacing={3} w="full">
            <Show if={!!cardHeading}>
              <Heading as="h5" variant="h5">
                {cardHeading}
              </Heading>
            </Show>
            <Text textStyle="label" color="text.subtle">
              {cardSubheading}
            </Text>
            <Grid templateColumns="repeat(4, 1fr)" gap={0} w="full" pb="3">
              {lineItems?.map(renderLineItem)}
            </Grid>
            <Grid
              templateColumns="repeat(4, 1fr)"
              w="full"
              py="3"
              borderTopWidth="1px"
            >
              <GridItem>
                <Heading variant="h6" w="auto">
                  Total
                </Heading>
              </GridItem>
              <GridItem></GridItem>
              <GridItem textAlign="right">
                <Heading variant="h6" textAlign="right">
                  {allInVariableRateCalc}/kg
                </Heading>
              </GridItem>
              <GridItem textAlign="right">
                <Heading variant="h6" textAlign="right">
                  {total ? prettyCurrency(total) : ''}
                </Heading>
              </GridItem>
            </Grid>
            <Show if={!!validUntil}>
              <Text
                textStyle="captionSmallRegular"
                justifySelf="flex-end"
                alignSelf="flex-end"
              >
                Valid until {validUntil}
              </Text>
            </Show>
          </VStack>
        </Box>
      </CardBody>
    </Card>
  );
};
