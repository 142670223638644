import { Circle, Divider, Flex, Stack, Text } from '@chakra-ui/react';
import { Show } from '@pelicargo/ui';
import { Fragment } from 'react';

type Props = {
  flightSegments: string[];
};

export const FlightPath = ({ flightSegments = [] }: Props) => {
  const renderFlightSegment = (segment: string, index: number) => {
    const isFirst = index === 0;
    const isLast = index === flightSegments?.length - 1;
    const isSecondToLast = index === flightSegments?.length - 2;

    return (
      <Fragment key={index}>
        <Flex>
          <Text
            textStyle="bodySmallRegular"
            color="text.subtle"
            fontWeight={isFirst || isLast ? 'bold' : 'normal'}
          >
            {segment}
          </Text>
        </Flex>
        <Show if={!isLast}>
          <Stack direction="row" align="center" spacing="-1" flex={1}>
            <Show if={isFirst || isLast}>
              <Circle
                size="8px"
                bg="transparent"
                border="2px"
                borderColor="border.default"
                mr="-0.5"
                zIndex={2}
              />
            </Show>
            <Divider
              borderColor="border.default"
              opacity="1"
              borderWidth="1px"
            />
            <Show if={isSecondToLast}>
              <Circle
                size="8px"
                bg="transparent"
                border="2px"
                ml="-0.5"
                borderColor="border.default"
                fontSize="sm"
              />
            </Show>
          </Stack>
        </Show>
      </Fragment>
    );
  };

  return (
    <Stack
      direction="row"
      align="center"
      spacing="1"
      width={{ base: '300px', lg: '500px' }}
    >
      {flightSegments?.map(renderFlightSegment)}
    </Stack>
  );
};
