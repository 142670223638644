import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Container,
  Divider,
  Heading,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  Address,
  Cargo,
  CargoType,
  DimensionUnit,
  EarliestTenderOption,
  RequestStatus,
  TimeOfDay,
  WeightUnit,
} from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import {
  formatAwb,
  getPrettyEarliestTenderOption,
  getPrettyTimeOfDay,
  prettyDate,
} from '@pelicargo/utils';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { HiChevronDown, HiSupport } from 'react-icons/hi';
import { useNavigate, useParams } from 'react-router-dom';

import { CancelBookingModal } from '../../components/CancelBookingModal';
import { DropOffCard } from '../../components/DropOffCard';
import { InstructionsCard } from '../../components/InstructionCard';
import { NewLabeledValue } from '../../components/NewLabeledValue';
import { Page } from '../../components/Page';
import { PriceCard } from '../../components/PriceCard';
import { RequestDetailsCard } from '../../components/RequestDetailsCard';
import { Timeline } from '../../components/Timeline';
import { UpdateTenderDateModal } from '../../components/UpdateTenderDateModal';
import { ENV } from '../../config/env';
import { trpc } from '../../config/trpc';
import { useAuth } from '../../hooks/auth/useAuth';
import { useFlightData } from '../../hooks/useFlightData';
import { useFrontChatBoot } from '../../hooks/useFrontChatBoot';
import { useTargetAccount } from '../../hooks/useTargetAccount';
import { CONTAINER_2XL } from '../../utils/constants';

type Params = {
  requestId?: string;
  quoteId?: string;
};

export const QuoteConfirmationDetails = () => {
  const toast = useToast();
  const params: Params = useParams();
  const { targetAccount } = useTargetAccount();
  const changeTenderDateModal = useDisclosure();
  const cancelBookingModal = useDisclosure();
  const { user } = useAuth();
  const [isDownloading, setIsDownloading] = useState(false);

  const [isBookingCancelled, setIsBookingCancelled] = useState(false);

  const { initialize } = useFrontChatBoot(document.body);

  const { data, isLoading, isRefetching, refetch, error } =
    trpc.getFinalQuote.useQuery(
      { id: Number(params?.quoteId) },
      {
        enabled: !!params?.quoteId,
        retry: false,
        refetchOnWindowFocus: false,
      },
    );
  const { data: frontSecret } = trpc.getFrontSecret.useQuery();
  const { mutateAsync: sendCustomerBookedQuote, isLoading: isEmailSending } =
    trpc.sendCustomerBookedQuote.useMutation();

  const { flightData } = useFlightData(data?.quote?.flight_path);

  const bookedQuoteVariant = data?.quote?.request?.booked_quote_variant;

  useEffect(() => {
    if (
      bookedQuoteVariant === null ||
      // Prevents flickering
      (!isNaN(bookedQuoteVariant?.quote_id) &&
        // Actual check to see if booking has been cancelled
        bookedQuoteVariant?.quote_id !== Number(params?.quoteId))
    ) {
      setIsBookingCancelled(true);
    }

    return () => {
      // Reset the state in the case that they cancel
      // and then book another one again, we want to make sure
      // the existing state doesn't persist
      setIsBookingCancelled(false);
    };
  }, [
    bookedQuoteVariant,
    data?.quote?.request?.booked_quote_variant,
    data?.quote?.request?.status,
    params?.quoteId,
  ]);

  useEffect(() => {
    refetch();
  }, [refetch, targetAccount?.id]);

  const handleSupport = () => {
    if (!initialize || !frontSecret) {
      return;
    }

    initialize({
      chatId: ENV.FRONT_CHAT_ID,
      token: frontSecret,
      email: user?.email,
      name: user?.full_name,
      requestId: params?.requestId,
      useDefaultLauncher: true,
    });
  };

  const handleDownloadPdf = async () => {
    const inputElement = document.getElementById('root');

    if (!inputElement) return;

    setIsDownloading(true);

    // Use html2canvas to capture a snapshot
    html2canvas(inputElement)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');

        // Initialize jsPDF
        const pdf = new jsPDF('p', 'mm', 'a4');

        // Add image to PDF
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        // Save PDF
        pdf.save('snapshot.pdf');
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  const handleEmailRequest = useCallback(async () => {
    try {
      await sendCustomerBookedQuote({
        requestId: data?.quote?.request?.id,
      });
      toast({
        title: 'Success',
        description: 'An email has been sent to you successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Something went wrong',
        description: error?.message,
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  }, [data?.quote?.request?.id, sendCustomerBookedQuote, toast]);

  const quote = useMemo(() => {
    return data?.quote;
  }, [data?.quote]);

  const tenderDate = useMemo(() => {
    if (quote?.tender_date_time_of_day) {
      return `${prettyDate(quote?.tender_date, {
        timeZone: 'UTC',
      })} - ${getPrettyTimeOfDay(quote?.tender_date_time_of_day as TimeOfDay)}`;
    }

    return prettyDate(quote?.tender_date, { timeZone: 'UTC' });
  }, [quote?.tender_date, quote?.tender_date_time_of_day]);

  const validUntil = useMemo(() => {
    return prettyDate(quote?.expires_at, {
      defaultDisplayValue: '',
    });
  }, [quote?.expires_at]);

  const alertMessage = useMemo(() => {
    if (quote?.request?.status === RequestStatus.BookingRequested) {
      return `Your booking is queued with ${quote?.airline?.name}.  You will receive an email once the flights are fully confirmed.`;
    }
    if (quote?.request?.status === RequestStatus.BookingChange) {
      return `We've received your request to edit the tender date and will let you know when the airline has confirmed the new booking.`;
    }
  }, [quote?.airline?.name, quote?.request?.status]);

  const stopCountLabel = useMemo(() => {
    const numStops = data?.quote?.flight_path?.length - 1;

    if (numStops === 0) return 'Nonstop';
    if (numStops === 1) return '1 stop';
    return `${numStops} stops`;
  }, [data?.quote?.flight_path?.length]);

  if (isLoading || isRefetching) {
    return (
      <Page title="Confirmation" flex="1" py="24">
        <Center>
          <Spinner />
        </Center>
      </Page>
    );
  }

  if (error) {
    return (
      <Page title="Confirmation" flex="1" py="24" w="full">
        <Center>
          <Alert status="error" variant="subtle" maxW="sm" borderRadius="lg">
            <AlertIcon />
            <Box>
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{error?.message}</AlertDescription>
            </Box>
          </Alert>
        </Center>
      </Page>
    );
  }

  if (isBookingCancelled) {
    return <BookingCancelledPage requestId={params.requestId} />;
  }

  const airlineRef = bookedQuoteVariant?.airline_ref;

  return (
    <Page title="Booking Confirmation" flex="1">
      <Container
        w="full"
        py={{ base: '8', lg: '10' }}
        px={{ base: '4', lg: '12' }}
        maxW={CONTAINER_2XL}
      >
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          justify="flex-end"
          w="full"
        >
          <Button rightIcon={<HiSupport />} onClick={handleSupport}>
            Support
          </Button>
          <Menu>
            <MenuButton
              rightIcon={<HiChevronDown />}
              as={Button}
              isLoading={isEmailSending || isDownloading}
            >
              Share
            </MenuButton>
            <MenuList>
              <MenuItem onClick={handleDownloadPdf}>Download</MenuItem>
              <MenuItem onClick={handleEmailRequest}>Email Me</MenuItem>
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton
              colorScheme="blue"
              rightIcon={<HiChevronDown />}
              as={Button}
            >
              Modify
            </MenuButton>
            <MenuList>
              <MenuItem onClick={changeTenderDateModal.onOpen}>
                Change Tender Date
              </MenuItem>
              <MenuItem color="error" onClick={cancelBookingModal.onOpen}>
                Cancel Booking
              </MenuItem>
            </MenuList>
          </Menu>
        </Stack>
        <Show
          if={
            quote?.request?.status === RequestStatus.BookingRequested ||
            quote?.request?.status === RequestStatus.BookingChange
          }
        >
          <Alert
            status="info"
            variant="top-accent"
            justifyContent="center"
            my="4"
          >
            <AlertIcon />

            {alertMessage}
          </Alert>
        </Show>
        <Stack direction="column" w="full" spacing="10">
          <Stack
            direction="row"
            w="full"
            spacing={{ base: 5, sm: 10 }}
            mt="4"
            wrap="wrap"
          >
            <NewLabeledValue
              label="Request#"
              value={`R${quote?.request?.id}`}
            />
            <Show if={!!quote?.request?.awb_number}>
              <NewLabeledValue
                label="AWB#"
                value={formatAwb(quote?.request?.awb_number, '-')}
              />
            </Show>
            <Show if={!!airlineRef}>
              <NewLabeledValue label="Airline Reference#" value={airlineRef} />
            </Show>
            <Show if={!!quote?.request?.customer_ref}>
              <NewLabeledValue
                label="Your Reference#"
                value={quote?.request?.customer_ref}
              />
            </Show>
          </Stack>
          <Stack direction={{ base: 'column', lg: 'row' }} spacing="6">
            <Stack w="full" spacing="6">
              <Show if={!!quote?.booking_instructions}>
                <InstructionsCard instructions={quote?.booking_instructions} />
              </Show>
              <Show if={!!quote?.cutoff_at || !!quote?.delivery_address}>
                <DropOffCard
                  earliestTender={getPrettyEarliestTenderOption(
                    quote?.earliest_tender as EarliestTenderOption,
                  )}
                  cutOffDate={prettyDate(quote?.cutoff_at, {
                    showTime: true,
                    timeZone: quote?.origin_airport?.timeZone,
                  })}
                  address={quote?.delivery_address as Address}
                  dropOffNotes={quote?.dropoff_instructions}
                />
              </Show>
              <Card borderRadius="lg">
                <CardHeader pl="4" pr="4" pt="3" pb="3">
                  <Stack>
                    <Stack direction="row" align="center" spacing="6">
                      <Image
                        src={quote?.airline?.logo_uri}
                        height="48px"
                        width="48px"
                        alt={quote?.airline?.name}
                      />
                      <Heading variant="h6">
                        {quote?.origin_airport_id}{' '}
                        <Box as="span" color="gray.400">
                          →
                        </Box>{' '}
                        {quote?.destination_airport_id}
                      </Heading>
                      <Text textStyle="bodySmallRegular">{stopCountLabel}</Text>
                    </Stack>
                    <Text color="text.subtle" textStyle="captionSmallRegular">
                      {quote?.airline?.name}
                    </Text>
                  </Stack>
                </CardHeader>
                <Stack>
                  <Divider orientation="horizontal" />
                </Stack>
                <CardBody>
                  <Timeline data={flightData} />
                </CardBody>
              </Card>
            </Stack>
            <Stack w="full" spacing="6">
              <PriceCard
                cardHeading="Cost Summary"
                cardSubheading={`${quote.airline.name} ${quote?.request?.booked_quote_variant?.name}`}
                lineItems={quote?.request?.booked_quote_variant?.line_items}
                validUntil={validUntil}
                cw={quote?.request?.chargeable_weight}
                showBorder
                padding="3"
              />
              <RequestDetailsCard
                cargoType={quote?.request?.cargo_type as CargoType}
                cargo={quote?.request?.cargo as Cargo[]}
                isKnownShipper={quote?.request?.is_known_shipper}
                commodity={quote?.request?.commodity}
                volumetricWeight={quote?.request?.volumetric_weight}
                grossWeight={quote?.request?.gross_weight}
                tenderDate={tenderDate}
                dimensionUnit={
                  quote?.request?.original_dimension_unit as DimensionUnit
                }
                weightUnit={quote?.request?.original_weight_unit as WeightUnit}
              />
            </Stack>
          </Stack>
        </Stack>
      </Container>
      <Show if={changeTenderDateModal.isOpen}>
        <UpdateTenderDateModal
          requestId={Number(params?.requestId)}
          refetch={refetch}
          isOpen={changeTenderDateModal.isOpen}
          onClose={changeTenderDateModal.onClose}
        />
      </Show>
      <Show if={cancelBookingModal.isOpen}>
        <CancelBookingModal
          requestId={Number(params?.requestId)}
          refetch={refetch}
          isOpen={cancelBookingModal.isOpen}
          onClose={cancelBookingModal.onClose}
        />
      </Show>
    </Page>
  );
};

type BookingCancelledPageProps = {
  requestId: string;
};

const BookingCancelledPage = ({ requestId }: BookingCancelledPageProps) => {
  const navigate = useNavigate();

  return (
    <Page title="Booking Cancelled" flex="1">
      <Card shadow="md" maxW="sm" margin="auto" marginY="40" px="4" py="3">
        <CardHeader fontWeight="bold" p="0">
          <Heading variant="h5">Booking Cancelled!</Heading>
        </CardHeader>
        <CardFooter p="0">
          <Stack direction="row" spacing="4" justify="flex-end" w="full">
            <Button onClick={() => navigate(`/requests/${requestId}`)}>
              Return to Quotes Listing
            </Button>
            <Button colorScheme="primary" onClick={() => navigate('/')}>
              New Request
            </Button>
          </Stack>
        </CardFooter>
      </Card>
    </Page>
  );
};
