import {
  defineStyleConfig,
  extendTheme,
  theme as baseTheme,
} from '@chakra-ui/react';

import {
  Button,
  CustomRadio,
  SimpleField,
  SimpleTooltip,
  Table,
} from './components';
import card from './components/card';
import formError from './components/form-error';
import Heading from './components/heading';
import labeledValue from './components/labeled-value';
import RadioCard from './components/radio-card';
import tag from './components/tag';
import * as foundations from './foundations';

const label = {
  fontSize: 'xs',
  fontWeight: 600,
  lineHeight: '12px',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
};

export const theme = extendTheme({
  ...foundations,
  colors: {
    ...foundations.colors,
    primary: {
      '50': '#FFF7E5',
      '100': '#FEEECB',
      '200': '#FDD997',
      '300': '#F9BC63',
      '400': '#F4A03D',
      '500': '#ED7500',
      '600': '#CB5A00',
      '700': '#AA4200',
      '800': '#892F00',
      '900': '#712100',
    },
    secondary: foundations.colors.blue,
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.8125rem',
    md: '0.875rem',
    lg: '1rem',
    xl: '1.125rem',
    '2xl': '1.25rem',
    '3xl': '1.5rem',
    '4xl': '1.875rem',
    '5xl': '2.25rem',
    '6xl': '3rem',
    '7xl': '3.75rem',
    '8xl': '4.5rem',
    '9xl': '6rem',
  },
  textStyles: {
    // Body
    bodyRegular: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '28px',
      color: 'text.default',
    },
    bodyMedium: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '28px',
      color: 'text.default',
    },
    bodySemibold: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '28px',
      color: 'text.default',
    },
    // Body Small
    bodySmallRegular: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      color: 'text.paragraph',
    },
    bodySmallMedium: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '24px',
      color: 'text.default',
    },
    bodySmallSemibold: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '24px',
      color: 'text.default',
    },
    // Caption
    captionRegular: {
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '20px',
      color: 'text.default',
    },
    captionMedium: {
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '20px',
      color: 'text.default',
    },
    captionSemibold: {
      fontWeight: 600,
      fontSize: '13px',
      lineHeight: '20px',
      color: 'text.default',
    },
    // Caption Small
    captionSmallRegular: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      color: 'text.subtle',
    },
    captionSmallMedium: {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '16px',
      color: 'text.default',
    },
    captionSmallSemibold: {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
      color: 'text.default',
    },
    label,
  },
  breakpoints: {
    base: '100%',
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1440px',
  },
  components: {
    ...baseTheme.components,
    Card: card,
    Tag: tag,
    Table,
    LabeledValue: labeledValue,
    Button,
    CustomRadio,
    SimpleField,
    SimpleTooltip,
    FormLabel: defineStyleConfig({
      variants: {
        inputLabel: label,
      },
    }),
    FormError: formError,
    RadioCard,
    Heading,
  },
});
