import { trpc, TrpcInputs, TrpcOutputs } from '../../config/trpc';

export type AccountAirports = TrpcOutputs['getAccountAirports'];

type useAccountAirport = {
  data: TrpcOutputs['getAccountAirports'];
  isFetching?: boolean;
  error?: any;
};

export const useAccountAirports = (
  queryParams: TrpcInputs['getAccountAirports'],
): useAccountAirport => {
  return trpc.getAccountAirports.useQuery(queryParams, {
    refetchOnWindowFocus: false,
  });
};
