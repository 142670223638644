import { Box, Stack, Tag, TagCloseButton, Textarea } from '@chakra-ui/react';
import { ChangeEvent, FocusEvent, KeyboardEvent, useRef } from 'react';
import { useWatch } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';

import { validateEmail } from '../utils/validateEmail';
import { SimpleField } from './SimpleField';

type Props = {
  name: string;
  placeholder?: string;
};

export const SimpleTagsInput = ({ name, placeholder }: Props) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const { setValue, setError } = useFormContext();
  const tags: string[] = useWatch({ name });

  const handleKeyUp = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();

    // TODO: fix this
    // @ts-ignore
    const newText = event?.target?.value.trim().replace(',', '') || '';

    if (
      (event.key === ',' || event.key === 'Enter' || event.code === 'Space') &&
      newText
    ) {
      const invalidMessage = validateEmail(newText);
      if (invalidMessage) {
        setError(name, {
          type: 'manual',
          message: invalidMessage,
        });
        return;
      }
      setValue(name, [...tags, newText]);
      ref.current!.value = '';
    } else if (event.key === 'Backspace' && !newText && tags?.length) {
      const updated = [...tags];
      updated.pop();
      setValue(name, updated);
    }
  };

  const handleBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
    event.preventDefault();

    const newText = event?.target?.value.trim().replace(',', '') || '';

    if (newText) {
      const invalidMessage = validateEmail(newText);
      if (invalidMessage) {
        setError(name, {
          type: 'manual',
          message: invalidMessage,
        });
        return;
      }
      setValue(name, [...tags, newText]);
      ref.current!.value = '';
    }
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value.trim();
    if (!value) ref.current!.value = '';
  };

  const handleDelete = (index: number) => () => {
    const updated = [...tags];
    updated.splice(index, 1);
    setValue(name, updated);
  };

  return (
    <SimpleField name={name} variant="outline">
      <Box w="full">
        <Stack direction="row" align="center" wrap="wrap">
          {tags?.map((tag, i) => (
            <Tag key={tag} colorScheme="gray">
              {tag}
              <TagCloseButton onClick={handleDelete(i)} />
            </Tag>
          ))}
        </Stack>
        <Textarea
          ref={ref}
          placeholder={placeholder}
          variant="unstyled"
          w="full"
          resize="none"
          fontSize="md"
          onKeyUp={handleKeyUp}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </Box>
    </SimpleField>
  );
};
