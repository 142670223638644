import { Box, FormLabel, SystemStyleObject } from '@chakra-ui/react';
import {
  ChakraStylesConfig,
  Props as ReactSelectProps,
  Select as RSelect,
} from 'chakra-react-select';
import { useRef, useState } from 'react';

export type Props = ReactSelectProps & {
  max?: number;
  defaultValue?: string;
  type?: string;
  label?: string;
};

export const Select = ({
  value: controlledValue,
  onChange: controlledOnChange,
  label,
  max,
  options,
  defaultValue,
  ...rest
}: Props) => {
  const selectorRef = useRef(null);
  const [value, setValue] = useState(defaultValue);

  const defaultOption = defaultValue
    ? { value: defaultValue, label: defaultValue }
    : '';

  const chakraStyles: ChakraStylesConfig = {
    control: (provided: SystemStyleObject) => ({
      ...provided,
      border: 0,
    }),
    dropdownIndicator: (provided: SystemStyleObject) => ({
      ...provided,
      bgColor: 'transparent',
      px: 2,
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    valueContainer: (provided: SystemStyleObject, { hasValue, isMulti }) => ({
      ...provided,
      px: hasValue && isMulti ? 2 : 4,
      py: 2,
      p: 1,
    }),
    option: (provided: SystemStyleObject) => ({
      ...provided,
      pl: 4,
    }),
    group: () => ({ pb: 2, pl: 1 }),
    groupHeading: (provided: SystemStyleObject) => ({
      ...provided,
      p: 4,
      ml: -4,
      fontSize: 16,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      borderWidth: 0,
    }),
  };

  const finalValue: any = controlledValue || value;
  const finalSetValue = controlledOnChange || setValue;
  const focusSelect = () => selectorRef.current.focus();

  return (
    <Box
      bg="bg.surface"
      flex={1}
      p={2}
      borderWidth={1}
      borderColor="border.default"
      borderRadius="lg"
      onClick={focusSelect}
      h="full"
    >
      <FormLabel variant="inputLabel" pl={1} onClick={focusSelect}>
        {label}
      </FormLabel>
      <RSelect
        chakraStyles={chakraStyles}
        tagVariant="solid"
        size="sm"
        isClearable={false}
        isMulti
        onChange={finalSetValue}
        value={finalValue}
        options={options}
        isOptionDisabled={() => !!max && finalValue?.length >= max}
        defaultValue={defaultOption}
        placeholder="Any"
        focusBorderColor="transparent"
        openMenuOnClick
        openMenuOnFocus
        ref={selectorRef}
        {...rest}
      />
    </Box>
  );
};
