import {
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverProps,
  Portal,
} from '@chakra-ui/react';
import { Hint as HintType } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import { HINTS } from '@pelicargo/utils';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { trpc } from '../config/trpc';
import { useAuth } from '../hooks/auth/useAuth';

type HintProps = PopoverProps & {
  name: string;
  children: ReactNode;
  isDisplaying?: boolean;
};

export const Hint = ({
  name,
  children,
  isDisplaying = true,
  ...rest
}: HintProps) => {
  const { user } = useAuth();
  const { mutateAsync: updateUserHint } = trpc.updateUserHint.useMutation();
  const [isUpdated, setIsUpdated] = useState(false);

  const hint: HintType = useMemo(
    () => {
      const hintData = (user?.hints as unknown as HintType[])?.find(
        (hint: HintType) => hint.name === name,
      );
      const hintContent = HINTS.find((hint: HintType) => hint.name === name);
      return {
        ...hintData,
        content: hintContent?.content,
      };
    },
    // Don't include user.hints because we don't
    // want to re-fetch the user if it's updated
    // otherwise it will blink the hint on the screen
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name],
  );

  const handleHintBeingDisplayed = useCallback(async () => {
    if (!user?.hints || !hint) return;

    const prevHints = (user?.hints as unknown as HintType[])?.filter(
      (hint: HintType) => hint.name !== name,
    );

    await updateUserHint({
      hints: [
        ...prevHints,
        {
          ...hint,
          displayed_at: new Date(),
        },
      ],
    });

    setIsUpdated(true);
  }, [hint, name, updateUserHint, user?.hints]);

  // Show hint if it hasn't been displayed or if the hint data exist
  const isOpen = useMemo(
    () => hint?.displayed_at === null && !!hint && !isUpdated && isDisplaying,
    [hint, isDisplaying, isUpdated],
  );

  useEffect(() => {
    if (isOpen) {
      handleHintBeingDisplayed();
    }
  }, [handleHintBeingDisplayed, hint, isDisplaying, isOpen]);

  return (
    <Popover
      placement="auto"
      onClose={handleHintBeingDisplayed}
      defaultIsOpen={isOpen}
      closeOnBlur={false}
      {...rest}
    >
      <PopoverAnchor>{children}</PopoverAnchor>
      <Portal>
        <PopoverContent bg="blue.500" color="white">
          <PopoverArrow bg="blue.500" />
          <PopoverCloseButton />
          <Show if={!!hint?.content?.title}>
            <PopoverHeader fontWeight="bold" borderBottom="none">
              {hint?.content?.title}
            </PopoverHeader>
          </Show>
          <Show if={!!hint?.content?.description}>
            <PopoverBody>{hint?.content?.description}</PopoverBody>
          </Show>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
