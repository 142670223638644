import {
  chakra,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Show } from '@pelicargo/ui';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { Dispatch, SetStateAction } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';

import { Pagination } from './Pagination';

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  columnVisibility?: any;
  dataCount: number;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  sorting?: SortingState;
  setSorting?: Dispatch<SetStateAction<SortingState>>;
  isRowDisabled?: (row: Row<Data>['original']) => boolean;
  onRowClick?: (row: Row<Data>['original']) => void;
  hidePagination?: boolean;
};

export const DataTable = <Data extends object>({
  data,
  columns,
  dataCount,
  pagination,
  setPagination,
  sorting,
  setSorting,
  columnVisibility,
  isRowDisabled,
  onRowClick,
  hidePagination = false,
}: DataTableProps<Data>) => {
  const table = useReactTable({
    columns,
    data,
    manualPagination: true,
    pageCount: Math.ceil(dataCount / pagination.pageSize),
    enableRowSelection: true,
    enableMultiRowSelection: false,
    enableSubRowSelection: false,
    enableSorting: true,
    manualSorting: true,
    state: {
      pagination,
      sorting,
      columnVisibility,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <>
      <TableContainer w="full" maxW="full" h="full" overflowY="auto">
        <Table w="full" position="relative" h="fit-content">
          <Thead
            position="sticky"
            top={0}
            bg="bg.subtle"
            borderBottomWidth="2px"
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                  const meta: any = header.column.columnDef.meta;
                  return (
                    <Th
                      key={header.id}
                      isNumeric={meta?.isNumeric}
                      fontSize="12px"
                      letterSpacing="2.5%"
                      lineHeight="16px"
                      fontWeight="600"
                      w={header.getSize()}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}

                      <chakra.span pl="4">
                        {header.column.getIsSorted() ? (
                          header.column.getIsSorted() === 'desc' ? (
                            <HiChevronDown aria-label="sorted descending" />
                          ) : (
                            <HiChevronUp aria-label="sorted ascending" />
                          )
                        ) : null}
                      </chakra.span>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody maxH="full">
            {table.getRowModel().rows.map((row) => (
              <Tr
                key={row.id}
                cursor={
                  isRowDisabled?.(row.original) ? 'not-allowed' : 'pointer'
                }
                _hover={{
                  bg: isRowDisabled?.(row.original) ? 'initial' : 'bg.muted',
                }}
                opacity={isRowDisabled?.(row.original) ? 0.5 : 1}
                onClick={() =>
                  isRowDisabled?.(row.original)
                    ? undefined
                    : onRowClick?.(row.original)
                }
              >
                {row.getVisibleCells().map((cell) => {
                  // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                  const meta: any = cell.column.columnDef.meta;
                  return (
                    <Td
                      key={cell.id}
                      isNumeric={meta?.isNumeric}
                      fontSize="13px"
                      fontWeight="400"
                      lineHeight="20px"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Show if={!hidePagination}>
        <Pagination
          page={table.getState().pagination.pageIndex}
          pageCount={table.getPageCount()}
          handleChange={(nextPage) => table.setPageIndex(nextPage - 1)}
          handlePrevious={table.previousPage}
          handleNext={table.nextPage}
          isPreviousDisabled={!table.getCanPreviousPage()}
          isNextDisabled={!table.getCanNextPage()}
        />
      </Show>
    </>
  );
};
