import { IconButton, IconButtonProps, Input, Stack } from '@chakra-ui/react';
import { ChangeEvent, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { HiMinus, HiPlus } from 'react-icons/hi';

import { SimpleField } from './SimpleField';

type Props = {
  name: string;
  label: string;
  min?: number;
  max?: number;
};

export const SimpleQuantityInput = ({ name, label, min = 1, max }: Props) => {
  const { setValue, watch } = useFormContext();
  const value = watch(name);

  const handleDecrement = useCallback(
    () => setValue(name, value === min ? value : value - 1),
    [setValue, name, value, min],
  );

  const handleIncrement = useCallback(() => {
    if (!value) {
      return setValue(name, 1);
    }
    setValue(name, value === max ? value : value + 1);
  }, [setValue, name, value, max]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(name, Number(event.target.value));
  };

  return (
    <SimpleField name={name} label={label}>
      <Stack
        direction="row"
        align="center"
        justifyContent="space-between"
        spacing="0"
      >
        <QuantityPickerButton
          onClick={handleDecrement}
          icon={<HiMinus />}
          isDisabled={value === min}
          aria-label="Decrement"
          variant="link"
          color="offBlack"
        />
        <Input
          type="number"
          inputMode="numeric"
          variant="unstyled"
          textAlign="center"
          fontSize="md"
          value={value}
          onChange={handleChange}
          _placeholder={{ color: 'text.placeholder' }}
        />
        <QuantityPickerButton
          onClick={handleIncrement}
          icon={<HiPlus />}
          isDisabled={!!value && value === max}
          aria-label="Increment"
          variant="link"
          color="offBlack"
        />
      </Stack>
    </SimpleField>
  );
};

const QuantityPickerButton = ({ ...rest }: IconButtonProps) => (
  <IconButton size="xs" fontSize="xl" {...rest} />
);
