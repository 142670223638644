import { formErrorAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(formErrorAnatomy.keys);

const baseStyle = definePartsStyle(() => {
  return {
    text: {
      mt: '1',
      color: 'red.600',
      fontSize: 'xs',
      fontWeight: 'bold',
    },
  };
});

export default defineMultiStyleConfig({
  baseStyle,
});
