export default {
  colors: {
    'bg.surface': {
      default: 'white',
      _dark: 'gray.900',
    },
    'bg.default': {
      default: 'gray.50',
      _dark: 'gray.800',
    },
    'bg.subtle': {
      default: 'gray.100',
      _dark: 'gray.700',
    },

    // Borders
    'border.light': {
      default: 'gray.200',
      _dark: 'gray.700',
    },
    'border.default': {
      default: 'gray.300',
      _dark: 'gray.600',
    },
    'border.hover': {
      default: 'gray.600',
      _dark: 'gray.200',
    },
    'border.focused': {
      default: 'gray.700',
      _dark: 'gray.300',
    },

    // States
    success: {
      default: 'green.500',
      _dark: 'green.200',
    },
    info: {
      default: 'blue.500',
      _dark: 'blue.200',
    },
    warning: {
      default: 'orange.500',
      _dark: 'orange.200',
    },
    error: {
      default: 'red.500',
      _dark: 'red.200',
    },

    // Text
    'text.default': {
      default: 'gray.800',
      _dark: 'white',
    },
    'text.paragraph': {
      default: 'gray.700',
      _dark: 'white',
    },
    'text.subtle': {
      default: 'gray.600',
      _dark: 'gray.300',
    },
    'text.placeholder': {
      default: 'gray.400',
      _dark: 'gray.200',
    },

    // Throw Away
    offBlack: {
      default: '#0e1012',
      _dark: 'gray.100',
    },
  },
  shadows: {
    xs: {
      default: '0px 0px 0px 1px rgba(0, 0, 0, 0.05)',
      _dark:
        '0px 0px 1px rgba(13, 14, 20, 1), 0px 1px 2px rgba(13, 14, 20, 0.9)',
    },
    sm: {
      default: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
      _dark:
        '0px 0px 1px rgba(13, 14, 20, 1), 0px 2px 4px rgba(13, 14, 20, 0.9)',
    },
    base: {
      default:
        '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
      _dark:
        '0px 0px 1px rgba(13, 14, 20, 1), 0px 4px 8px rgba(13, 14, 20, 0.9)',
    },
    md: {
      default:
        '0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
      _dark:
        '0px 0px 1px rgba(13, 14, 20, 1), 0px 4px 8px rgba(13, 14, 20, 0.9)',
    },
    lg: {
      default:
        '0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
      _dark:
        '0px 0px 1px rgba(13, 14, 20, 1), 0px 8px 16px rgba(13, 14, 20, 0.9)',
    },
    xl: {
      default:
        '0px 20px 25px -5px rgba(0, 0, 0, 0.10), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
      _dark:
        '0px 0px 1px rgba(13, 14, 20, 1), 0px 16px 24px rgba(13, 14, 20, 0.9)',
    },
    '2xl': {
      default: '0px 25px 50px -12px rgba(0, 0, 0, 0.25)',
      _dark:
        '0px 0px 1px rgba(13, 14, 20, 1), 0px 16px 24px rgba(13, 14, 20, 0.9)',
    },
    '3xl': {
      default: '0px 25px 50px -12px rgba(0, 0, 0, 0.25)',
      _dark:
        '0px 0px 1px rgba(13, 14, 20, 1), 0px 16px 24px rgba(13, 14, 20, 0.9)',
    },
    focus: {
      default: '0 0 0 4px #EDF2F7',
      _dark: '0 0 0 4px #2D3748',
    },
  },
};
