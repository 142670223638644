import { Box, Heading, Stack } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';

import { CargoPlane } from '../../components/CargoPlane';
import { SimpleRequestForm } from '../../components/Forms/SimpleRequestForm';
import { Hint } from '../../components/Hint';
import { Page } from '../../components/Page';
import { useAuth } from '../../hooks/auth/useAuth';
import { RequestFormValues } from '../../hooks/request/useForm';
import { formatRequestForm } from '../../utils/formatRequestForm';

export const Home = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const [, setRequest] = useSessionStorage('request', {});

  const onSubmit = useCallback(
    async (values: RequestFormValues) => {
      const formattedRequestForm = formatRequestForm(values);
      setRequest(formattedRequestForm);

      if (!isLoggedIn) {
        navigate('/login');
        return;
      }

      navigate('/request-quote');
    },
    [isLoggedIn, navigate, setRequest],
  );

  return (
    <Page title="Home" flex="1" position="relative">
      <CargoPlane maxW="800px" bottom="0" />
      <Stack
        spacing={4}
        px={{ base: '4', md: '8', lg: '20', xl: '40' }}
        py={{ base: '16', md: '16', lg: '20' }}
        maxW="full"
        w="full"
        minH="90vh"
        h="full"
      >
        <Heading variant="h1">Choose the best quotes</Heading>
        <Hint name="home-requestForm" placement="auto-start">
          <Box w="full" maxW="4xl" zIndex="2">
            <SimpleRequestForm onSubmit={onSubmit} />
          </Box>
        </Hint>
      </Stack>
    </Page>
  );
};
