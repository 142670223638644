import { SimpleGrid, SimpleGridProps } from '@chakra-ui/react';

import { dayListGenerator } from '../utils/dayListGenerator';
import { DateRulesFn } from '../utils/dayListGenerator';
import { Day } from './Day';
import { DayStyleConfig } from './type';

type Props = {
  year: number;
  month: number;
  onSetDate?: (date: Date) => void;
  onGetDayTypeRulesFn: DateRulesFn;
  dayStyleConfig?: Partial<DayStyleConfig>;
} & SimpleGridProps;

export const Calendar = ({
  year,
  month,
  dayStyleConfig,
  onSetDate,
  onGetDayTypeRulesFn,
  ...props
}: Props) => {
  const dayList = dayListGenerator(year, month, onGetDayTypeRulesFn);

  return (
    <SimpleGrid {...props} columns={7} rowGap={2} w="full">
      {dayList.map((dateObj) => {
        return (
          <Day
            key={dateObj.date.toISOString()}
            onSetDate={onSetDate}
            {...dateObj}
            dayStyleConfig={dayStyleConfig}
          />
        );
      })}
    </SimpleGrid>
  );
};
