export const formatAwb = (awb: string, defaultValue?: string) => {
  if (!awb) return defaultValue || '';

  // Remove all dashes and trim the string
  const cleanedAwb = awb.replace(/-/g, '').trim();

  return cleanedAwb?.length > 3
    ? `${cleanedAwb?.slice(0, 3)}-${cleanedAwb.slice(3)}`
    : cleanedAwb;
};

export const removeDash = (value?: string) =>
  value?.replace(/[\W\s\._\-]+/g, '') || '';

export const addDash = (value: string) =>
  value?.slice(0, 3) + '-' + value?.slice(3);
