import { ENVS } from '@pelicargo/types';
import { z } from 'zod';

const rawAppEnv = import.meta.env.VITE_APP_ENV;

if (rawAppEnv && !rawAppEnv.includes(rawAppEnv as ENVS)) {
  throw new Error('Invalid APP_ENV. Please select one of: dev, stg, prd');
}

const envVariables = z.object({
  APP_ENV: z.string(),
  API_BASE_URL: z.string(),
  CI: z.string().optional(),
  FRONT_CHAT_ID: z.string(),
  HJ_ID: z.string(),
  HJ_SV: z.string(),
  MIXPANEL_TOKEN: z.string(),
  SENTRY_AUTH_TOKEN: z.string(),
  SENTRY_DSN: z.string(),
  SUPABASE_URL: z.string(),
  SUPABASE_KEY: z.string(),
  WS_API_URL: z.string(),
});

const env = {
  APP_ENV: import.meta.env.VITE_APP_ENV,
  API_BASE_URL: import.meta.env.VITE_API_BASE_URL,
  CI: import.meta.env.VITE_CI,
  FRONT_CHAT_ID: import.meta.env.VITE_FRONT_CHAT_ID,
  HJ_ID: import.meta.env.VITE_HJ_ID,
  HJ_SV: import.meta.env.VITE_HJ_SV,
  MIXPANEL_TOKEN: import.meta.env.VITE_MIXPANEL_TOKEN,
  SENTRY_AUTH_TOKEN: import.meta.env.VITE_SENTRY_AUTH_TOKEN,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  SUPABASE_URL: import.meta.env.VITE_SUPABASE_URL,
  SUPABASE_KEY: import.meta.env.VITE_SUPABASE_KEY,
  WS_API_URL: import.meta.env.VITE_WS_API_URL,
};

let ENV: z.infer<typeof envVariables> = {};

try {
  ENV = envVariables.parse(env);
} catch (error) {
  if (error instanceof z.ZodError) {
    for (const issue of error.issues) {
      console.error(
        `CUSTOMER ENV VARIABLE ERROR: VITE_${issue.path[0]} is missing`,
      );
    }
  }
}

export { ENV };
