import { IconButton, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { AircraftType } from '@pelicargo/types';
import { HiChevronLeft } from 'react-icons/hi';

import { QuoteFilters } from '../../hooks/useQuoteFilters';

type Props = {
  filters: QuoteFilters;
  handleRadioFilterChange: (key: string) => (nextValue: string) => void;
  resetView: () => void;
};

export const AircraftFilter = ({
  filters,
  handleRadioFilterChange,
  resetView,
}: Props) => {
  return (
    <Stack>
      <Stack direction="row" align="center" spacing="2">
        <IconButton
          aria-label="Go back"
          icon={<HiChevronLeft />}
          variant="link"
          size="sm"
          display={{ base: 'flex', md: 'none' }}
          minW="auto"
          onClick={resetView}
        />
        <Text textStyle="label">Aircrafts</Text>
      </Stack>

      <RadioGroup
        value={filters?.aircraft}
        onChange={handleRadioFilterChange('aircraft')}
      >
        <Stack>
          <Radio value="any">Any aircraft type</Radio>
          <Radio value={AircraftType.RFS}>Freighter & Trucks only</Radio>
        </Stack>
      </RadioGroup>
    </Stack>
  );
};
