import { Checkbox, CheckboxProps } from '@chakra-ui/checkbox';
import { Text } from '@chakra-ui/react';
import { Show } from '@pelicargo/ui';
import { ChangeEvent, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

import { SimpleField, SimpleXProps } from './SimpleField';
import { useSimpleFormContext } from './SimpleForm';

type SimpleCheckboxProps<FormSchema> = SimpleXProps<FormSchema> &
  CheckboxProps & {
    ignoreOnBlur?: boolean;
    fieldLabel?: string;
    children?: ReactNode;
  };

export const SimpleCheckbox = ({
  name,
  label,
  fieldLabel = '',
  helper,
  ignoreOnBlur = false,
  variant,
  size = 'lg',
  colorScheme = 'primary',
  children,
  ...rest
}: SimpleCheckboxProps<any>) => {
  const { simpleOnBlur } = useSimpleFormContext();
  const { register, watch } = useFormContext();
  const value = watch(name);

  const formProps = register(name);

  const updateForm = (event: ChangeEvent<HTMLInputElement>) => {
    formProps.onBlur(event);
    if (ignoreOnBlur) return;
    simpleOnBlur?.(event);
  };

  return (
    <SimpleField
      name={name}
      helper={helper}
      label={fieldLabel}
      variant={variant}
      {...rest}
    >
      <Checkbox
        size={size}
        colorScheme={colorScheme}
        variant={variant}
        isChecked={value}
        onChange={updateForm}
        spacing="4"
        {...formProps}
      >
        <Show if={!!label}>
          <Text>{label}</Text>
        </Show>
        <Show if={!!children}>{children}</Show>
      </Checkbox>
    </SimpleField>
  );
};
