import { cmToIn } from '@pelicargo/utils';

import { API } from './apiTypes';

export const formatIataCode = (input: string): string => {
  if (!input) {
    return '';
  }

  const cleaned = input?.replace(/[^0-9]/g, '');
  let formatted = '';

  for (let i = 0; i < cleaned?.length; i++) {
    if (i === 3 || i === 7) {
      formatted += '-';
    }
    formatted += cleaned[i];
  }

  return formatted;
};

export const formatAwbNumber = (input: string) => {
  if (!input) return '';

  // Remove any characters other than alphanumerics and replace first 3 characters with prefix if provided
  const cleaned = input?.replace(/[^a-zA-Z0-9]/g, '');

  let formatted = cleaned;

  if (cleaned?.length === 11) {
    // Add a dash after the first 3 characters
    for (let i = 0; i < cleaned?.length; i++) {
      if (i === 3) {
        formatted =
          formatted?.slice(0, i) + '-' + formatted?.slice(i, formatted?.length);
      }
    }
  }

  return formatted;
};

export const formatCargo = (data: API.Cargo): API.Cargo => {
  if (!data) return;
  return {
    ...data,
    length: Number(cmToIn(data?.length)?.toFixed(2)),
    width: Number(cmToIn(data?.width)?.toFixed(2)),
    height: Number(cmToIn(data?.height)?.toFixed(2)),
  };
};
