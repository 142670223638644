import {
  Button,
  Card,
  CardBody,
  CardProps,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { Show } from '@pelicargo/ui';
import { prettyCurrency, prettyDate } from '@pelicargo/utils';
import { useCallback, useMemo } from 'react';
import { HiChevronRight } from 'react-icons/hi2';
import { useNavigate, useParams } from 'react-router-dom';

import { API } from '../../utils/apiTypes';
import { getLineItemsTotal } from '../../utils/getLineItemTotal';
import { PriceCard } from '../PriceCard';

type Props = CardProps & {
  quoteVariants: API.RequestQuote['quote_variants'];
  cw: API.RequestQuote['chargeable_weight'];
  expiresAt: API.RequestQuote['expires_at'];
  tabIndex: number;
  showBookQuoteButton?: boolean;
  onTabChange: (index: number) => void;
};

export const PriceCardTabs = ({
  quoteVariants,
  cw,
  expiresAt,
  tabIndex,
  showBookQuoteButton = false,
  onTabChange,
  ...rest
}: Props) => {
  const navigate = useNavigate();
  const variantRates = useMemo(() => {
    return quoteVariants?.map((variant) => {
      const total = getLineItemsTotal(variant.line_items);
      return total / cw;
    });
  }, [quoteVariants, cw]);
  const quote_id = quoteVariants?.[tabIndex].quote_id;
  const { requestId } = useParams<{ requestId: string }>();

  const calculateRateDifference = useCallback(
    (currentVariantIndex: number, otherVariantIndex: number) => {
      return (
        variantRates[otherVariantIndex] - variantRates[currentVariantIndex]
      );
    },
    [variantRates],
  );

  const renderQuoteVariantTab = (
    quoteVariant: API.QuoteVariant,
    index: number,
  ) => {
    const rateDifference = calculateRateDifference(tabIndex, index);
    const prettyRate = prettyCurrency(rateDifference);
    const rateDifferenceLabel =
      rateDifference < 0 ? `${prettyRate}/kg` : `+${prettyRate}/kg`;

    return (
      <Tab
        key={quoteVariant?.id}
        w="full"
        color="text.default"
        borderColor="border.light"
        borderRightWidth={1}
        _last={{ borderRightWidth: '0' }}
        _selected={{
          borderBottomColor: 'primary.600',
          color: 'primary.600',
          '& .qv-name': { color: 'primary.600' },
          '& .qv-difference': { display: 'none' },
        }}
      >
        <Stack direction="row" spacing="1" align="center">
          <Text textStyle="bodyMedium" className="qv-name">
            {quoteVariant?.type}
          </Text>
          <Text
            textStyle="bodySmallRegular"
            color="text.subtle"
            className="qv-difference"
          >
            {rateDifferenceLabel}
          </Text>
        </Stack>
      </Tab>
    );
  };

  const renderQuoteVariantTabPanel = (quoteVariant: API.QuoteVariant) => {
    return (
      <TabPanel key={quoteVariant?.id} p="0">
        <Stack>
          <PriceCard
            cardSubheading={`${quoteVariant.name}`}
            lineItems={quoteVariant?.line_items}
            cw={cw}
          />
          <Show if={showBookQuoteButton}>
            <Button
              colorScheme="primary"
              size="sm"
              iconSpacing="2"
              alignSelf="flex-end"
              onClick={() =>
                navigate(`/requests/${requestId}/quotes/${quote_id}`, {
                  state: { quoteVariantIndex: tabIndex },
                })
              }
              rightIcon={<HiChevronRight strokeWidth="2" />}
            >
              <Text textStyle="bodySemibold" color="white">
                Book Quote
              </Text>
            </Button>
          </Show>
          <Show if={!!expiresAt}>
            <Stack align="flex-end" justify="flex-end">
              <Text textStyle="captionSmallRegular">
                Valid until {prettyDate(expiresAt)}
              </Text>
            </Stack>
          </Show>
        </Stack>
      </TabPanel>
    );
  };

  return (
    <Card p="0" {...rest}>
      <CardBody p="0">
        <Tabs
          colorScheme="primary"
          index={tabIndex}
          onChange={onTabChange}
          p="0"
        >
          <TabList w="full">
            {quoteVariants?.map(renderQuoteVariantTab)}
          </TabList>

          <TabPanels px="4" py="3">
            {quoteVariants?.map(renderQuoteVariantTabPanel)}
          </TabPanels>
        </Tabs>
      </CardBody>
    </Card>
  );
};
