type PrettyCurrencyOptions = {
  locale: string;
  currency: string;
  isCents: boolean;
};

const defaultOptions: PrettyCurrencyOptions = {
  locale: 'en-US',
  currency: 'USD',
  isCents: true,
};

const isNumber = (num?: number | null): num is number => {
  return num !== undefined && num !== null && !isNaN(num);
};

/**
 * @description Format a number to a standard currency format. Assumes the number is in cents.
 */
export const prettyCurrency = (
  amount?: number | null,
  options?: Partial<PrettyCurrencyOptions>,
) => {
  if (!isNumber(amount)) return '-';

  const { locale, currency, isCents } = { ...defaultOptions, ...options };
  const value = isCents ? amount / 100 : amount;

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(value);
};
