import { AircraftType } from '@prisma/client';

const PASSENGER_AIRCRAFT_TYPES: AircraftType[] = [
  AircraftType.WideBody,
  AircraftType.NarrowBody,
];

export const isPax = (type: AircraftType) => {
  return PASSENGER_AIRCRAFT_TYPES.includes(type);
};
