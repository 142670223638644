import { prettyDate } from '@pelicargo/utils';
import { formatDuration, intervalToDuration, parseISO } from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import { useMemo } from 'react';

import { API } from '../utils/apiTypes';

const calculateLayoverDuration = (departsAt: string, arrivesAt: string) => {
  if (!departsAt || !arrivesAt) return '';
  const departureDate = parseISO(departsAt);
  const arrivalDate = parseISO(arrivesAt);
  const duration = intervalToDuration({
    start: departureDate,
    end: arrivalDate,
  });
  return formatDuration(duration);
};

// Convert an ISO string to a Date object in the specified timezone
const parseDate = (dateString: string, timeZone: string): Date => {
  const date = fromZonedTime(dateString, timeZone);
  return toZonedTime(date, timeZone);
};

// Format the duration between two dates
const getFormattedDuration = (start: Date, end: Date): string => {
  const duration = intervalToDuration({ start, end });
  return formatDuration(duration);
};

type Departure = {
  time: string;
  timezone: string;
};

type Arrival = {
  time: string;
  timezone: string;
};

const calculateFlightTime = (departure: Departure, arrival: Arrival) => {
  if (!arrival?.time || !departure?.time) return null;

  const departureTime = parseDate(departure.time, departure?.timezone);
  const arrivalTime = parseDate(arrival.time, arrival?.timezone);

  return getFormattedDuration(departureTime, arrivalTime);
};

export type IFlightStop = {
  variant: 'stop';
  type: 'depart' | 'arrive';
  date: string;
  time: string;
  airportName: string;
  airportId: string;

  showDateTime: boolean;
};

export type IFlightLeg = {
  variant: 'leg';
  flightDuration: string;
  airlineLogo: string;
  airlineId: string;
  flightNumber: string;
  aircraftType: string;
  aircraftName: string;
};

export type IFlightLayover = {
  variant: 'layover';
  layoverDuration: string | null;
};

export type FlightData = IFlightStop | IFlightLeg | IFlightLayover;

export const useFlightData = (flightPath: API.FinalQuote['flight_path']) => {
  const flightData = useMemo(() => {
    const sequentialData: FlightData[] = [];

    if (!flightPath) return sequentialData;

    const showDateTime = flightPath.some(
      (leg) => leg?.departs_at || leg?.arrives_at,
    );

    flightPath?.forEach((leg, index) => {
      const nextLeg = flightPath?.[index + 1];

      // Add departure stop
      sequentialData.push({
        variant: 'stop',
        type: 'depart',
        showDateTime,
        time: prettyDate(leg?.departs_at, {
          timeZone: leg?.origin_airport?.timeZone,
          onlyTime: true,
        }),
        date: prettyDate(leg?.departs_at, {
          timeZone: leg?.origin_airport?.timeZone,
          hideYear: true,
        }),
        airportName: leg?.origin_airport?.name,
        airportId: leg?.origin_airport?.id,
      });

      // Add flight leg
      sequentialData.push({
        variant: 'leg',
        flightDuration: calculateFlightTime(
          {
            time: leg?.departs_at as any,
            timezone: leg?.origin_airport?.timeZone,
          },
          {
            time: leg?.arrives_at as any,
            timezone: leg?.destination_airport?.timeZone,
          },
        ),
        airlineLogo: leg?.airline?.logo_uri ?? '',
        airlineId: leg?.airline_id,
        flightNumber: leg?.flight_number,
        aircraftType: leg?.aircraft_type,
        aircraftName: leg?.aircraft?.name ?? '',
      });

      // Add arrival stop
      sequentialData.push({
        variant: 'stop',
        type: 'arrive',
        showDateTime,
        time: prettyDate(leg?.arrives_at, {
          timeZone: leg?.destination_airport?.timeZone,
          onlyTime: true,
        }),
        date: prettyDate(leg?.arrives_at, {
          timeZone: leg?.destination_airport?.timeZone,
          hideYear: true,
        }),
        airportName: leg?.destination_airport?.name,
        airportId: leg?.destination_airport?.id,
      });

      // Add layover if there is a next leg
      if (nextLeg) {
        sequentialData.push({
          variant: 'layover',
          layoverDuration: calculateLayoverDuration(
            leg?.arrives_at as any,
            nextLeg?.departs_at as any,
          ),
        });
      }
    });

    return sequentialData;
  }, [flightPath]);

  const shouldShowFlightData = useMemo(() => {
    return flightData?.every((data) => {
      if (data?.variant !== 'stop') return true;
      return data?.airportName && data?.airportId;
    });
  }, [flightData]);

  return {
    flightData,
    shouldShowFlightData,
  };
};
