import { Box, BoxProps, Image } from '@chakra-ui/react';

import CargoPlaneBase from '../assets/pelicargo-plane.svg';

type Props = BoxProps;

export const CargoPlane = ({ ...rest }: Props) => {
  return (
    <Box
      position="absolute"
      right={0}
      bottom="4"
      pb="5vh"
      overflow="hidden"
      zIndex="1"
      {...rest}
    >
      <Image
        src={CargoPlaneBase}
        bottom="10%"
        sx={{ perspective: '800px' }}
        width="full"
        right={0}
        zIndex="1"
        alt="airplane"
        opacity="0.5"
      />
    </Box>
  );
};
