import {
  Container,
  Heading,
  ListItem,
  OrderedList,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';

import { Page } from '../../components/Page';

export const Privacy = () => {
  return (
    <Page title="Terms & Conditions">
      <Container maxW="container.lg" p="8">
        <Stack>
          <Heading as="h3" variant="h3">
            Privacy Notice
          </Heading>
          <Text fontSize="lg">Last updated November 12, 2021</Text>
        </Stack>
        <Stack spacing="6" pt="8">
          <Stack>
            <Text fontSize="lg">
              Thank you for choosing to be part of our community at Pelicargo
              Inc. ("Company," "we," "us," or "our"). We are committed to
              protecting your personal information and your right to privacy. If
              you have any questions or concerns about this privacy notice or
              our practices with regard to your personal information, please
              contact us at{' '}
              <a href="mailto:info@pelicargo.com">info@pelicargo.com</a>.
            </Text>
            <Text fontSize="lg">
              This privacy notice describes how we might use your information if
              you:
            </Text>

            <UnorderedList>
              <ListItem>
                Visit our website at{' '}
                <a href="http://www.pelicargo.com">http://www.pelicargo.com</a>
              </ListItem>
              <ListItem>
                Engage with us in other related ways ― including any sales,
                marketing, or events
              </ListItem>
            </UnorderedList>

            <Text fontSize="lg">In this privacy notice, if we refer to:</Text>
            <UnorderedList>
              <ListItem>
                "Website," we are referring to any website of ours that
                references or links to this policy
              </ListItem>
              <ListItem>
                "Services," we are referring to our Website, and other related
                services, including any sales, marketing, or events
              </ListItem>
            </UnorderedList>

            <Text fontSize="lg">
              The purpose of this privacy notice is to explain to you in the
              clearest way possible what information we collect, how we use it,
              and what rights you have in relation to it. If there are any terms
              in this privacy notice that you do not agree with, please
              discontinue use of our Services immediately.
            </Text>

            <Text fontSize="lg">
              Please read this privacy notice carefully, as it will help you
              understand what we do with the information that we collect.
            </Text>
          </Stack>
          <Stack>
            <Heading as="h5" size="lg">
              TABLE OF CONTENTS
            </Heading>
            <OrderedList>
              <ListItem>WHAT INFORMATION DO WE COLLECT?</ListItem>
              <ListItem>HOW DO WE USE YOUR INFORMATION?</ListItem>
              <ListItem>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</ListItem>
              <ListItem>WHO WILL YOUR INFORMATION BE SHARED WITH?</ListItem>
              <ListItem>
                DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </ListItem>

              <ListItem>HOW LONG DO WE KEEP YOUR INFORMATION?</ListItem>
              <ListItem>HOW DO WE KEEP YOUR INFORMATION SAFE?</ListItem>
              <ListItem>DO WE COLLECT INFORMATION FROM MINORS?</ListItem>

              <ListItem>WHAT ARE YOUR PRIVACY RIGHTS?</ListItem>

              <ListItem>CONTROLS FOR DO-NOT-TRACK FEATURES</ListItem>

              <ListItem>
                DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </ListItem>
              <ListItem>DO WE MAKE UPDATES TO THIS NOTICE?</ListItem>
              <ListItem>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</ListItem>
              <ListItem>
                HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM
                YOU?
              </ListItem>
            </OrderedList>
          </Stack>
          <Stack>
            <Heading as="h5" size="lg">
              WHAT INFORMATION DO WE COLLECT?
            </Heading>
            <i>Personal information you disclose to us</i>
            <Text fontSize="lg">
              In Short: We collect personal information that you provide to us.
            </Text>

            <Text fontSize="lg">
              We collect personal information that you voluntarily provide to us
              when you register on the Website, express an interest in obtaining
              information about us or our products and Services, when you
              participate in activities on the or otherwise when you contact us.
            </Text>

            <Text fontSize="lg">
              The personal information that we collect depends on the context of
              your interactions with us and the Website, the choices you make
              and the products and features you use. The personal information we
              collect may include the following:
            </Text>

            <Text fontSize="lg">
              Personal Information Provided by You. We collect names; phone
              numbers; email addresses; mailing addresses; job titles;
              usernames; passwords; contact or authentication data; billing
              addresses; and other similar information.
            </Text>

            <Text fontSize="lg">
              Payment Data. We may collect data necessary to process your
              payment if you make purchases, such as your payment instrument
              number (such as a credit card number), and the security code
              associated with your payment instrument.
            </Text>

            <Text fontSize="lg">
              All personal information that you provide to us must be true,
              complete and accurate, and you must notify us of any changes to
              such personal information.
            </Text>

            <i>Information automatically collected</i>

            <Text fontSize="lg">
              In Short: Some information — such as your Internet Protocol (IP)
              address and/or browser and device characteristics — is collected
              automatically when you visit our Website.
            </Text>

            <Text fontSize="lg">
              We automatically collect certain information when you visit, use
              or navigate the Website. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Website and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Website, and for our
              internal analytics and reporting purposes.
            </Text>

            <Text fontSize="lg">
              Like many businesses, we also collect information through cookies
              and similar technologies.
            </Text>

            <Text fontSize="lg">The information we collect includes:</Text>

            <ul>
              <li>
                Log and Usage Data. Log and usage data is service-related,
                diagnostic, usage and performance information our servers
                automatically collect when you access or use our Website and
                which we record in log files. Depending on how you interact with
                us, this log data may include your IP address, device
                information, browser type and settings and information about
                your activity in the Website (such as the date/time stamps
                associated with your usage, pages and files viewed, searches and
                other actions you take such as which features you use), device
                event information (such as system activity, error reports
                (sometimes called 'crash dumps') and hardware settings).
              </li>
            </ul>

            <i>Information collected from other sources</i>

            <Text fontSize="lg">
              In Short: We may collect limited data from public databases,
              marketing partners, and other outside sources.
            </Text>
            <Text fontSize="lg">
              In order to enhance our ability to provide relevant marketing,
              offers and services to you and update our records, we may obtain
              information about you from other sources, such as public
              databases, joint marketing partners, affiliate programs, data
              providers, as well as from other third parties. This information
              includes mailing addresses, job titles, email addresses, phone
              numbers, intent data (or user behavior data), Internet Protocol
              (IP) addresses, social media profiles, social media URLs and
              custom profiles, for purposes of targeted advertising and event
              promotion.
            </Text>
          </Stack>
          <Stack>
            <Heading as="h5" size="lg">
              2. HOW DO WE USE YOUR INFORMATION?
            </Heading>

            <Text fontSize="lg">
              In Short: We process your information for purposes based on
              legitimate business interests, the fulfillment of our contract
              with you, compliance with our legal obligations, and/or your
              consent.
            </Text>

            <Text fontSize="lg">
              We use personal information collected via our Website for a
              variety of business purposes described below. We process your
              personal information for these purposes in reliance on our
              legitimate business interests, in order to enter into or perform a
              contract with you, with your consent, and/or for compliance with
              our legal obligations. We indicate the specific processing grounds
              we rely on next to each purpose listed below.
            </Text>

            <Text fontSize="lg">
              We use the information we collect or receive:
            </Text>

            <ul>
              <li>
                To facilitate account creation and logon process. If you choose
                to link your account with us to a third-party account, we use
                the information you allowed us to collect from those third
                parties to facilitate account creation and logon process for the
                performance of the contract.
              </li>

              <li>
                To post testimonials. We post testimonials on our Website that
                may contain personal information. Prior to posting a
                testimonial, we will obtain your consent to use your name and
                the content of the testimonial. If you wish to update, or delete
                your testimonial, please contact us at
                <a href="mailto:info@pelicargo.com">info@pelicargo.com</a> and
                be sure to include your name, testimonial location, and contact
                information.
              </li>

              <li>
                Request feedback. We may use your information to request
                feedback and to contact you about your use of our Website.
              </li>
              <li>
                To enable user-to-user communications. We may use your
                information in order to enable user-to-user communications with
                each user's consent.
              </li>

              <li>
                To manage user accounts. We may use your information for the
                purposes of managing our account and keeping it in working
                order.
              </li>

              <li>
                To send administrative information to you. We may use your
                personal information to send you product, service and new
                feature information and/or information about changes to our
                terms, conditions, and policies.
              </li>

              <li>
                To protect our Services. We may use your information as part of
                our efforts to keep our Website safe and secure (for example,
                for fraud monitoring and prevention).
              </li>

              <li>
                To enforce our terms, conditions and policies for business
                purposes, to comply with legal and regulatory requirements or in
                connection with our contract.
              </li>

              <li>
                To respond to legal requests and prevent harm. If we receive a
                subpoena or other legal request, we may need to inspect the data
                we hold to determine how to respond.
              </li>
              <li>
                Fulfill and manage your orders. We may use your information to
                fulfill and manage your orders and payments made through the
                Website.
              </li>

              <li>
                To deliver and facilitate delivery of services to the user. We
                may use your information to provide you with the requested
                service.
              </li>

              <li>
                To respond to user inquiries/offer support to users. We may use
                your information to respond to your inquiries and solve any
                potential issues you might have with the use of our Services.
              </li>

              <li>
                To send you marketing and promotional communications. We and/or
                our third-party marketing partners may use the personal
                information you send to us for our marketing purposes, if this
                is in accordance with your marketing preferences. For example,
                when expressing an interest in obtaining information about us or
                our Website, subscribing to marketing or otherwise contacting
                us, we will collect personal information from you. You can
                opt-out of our marketing emails at any time (see the "WHAT ARE
                YOUR PRIVACY RIGHTS?" below).
              </li>

              <li>
                Deliver targeted advertising to you. We may use your information
                to develop and display personalized content and advertising (and
                work with third parties who do so) tailored to your interests
                and/or location and to measure its effectiveness.
              </li>
            </ul>
          </Stack>
          <Stack>
            <Heading as="h5" size="lg">
              3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
            </Heading>

            <Text fontSize="lg">
              In Short: We only share information with your consent, to comply
              with laws, to provide you with services, to protect your rights,
              or to fulfill business obligations.
            </Text>

            <Text fontSize="lg">
              We may process or share your data that we hold based on the
              following legal basis:
            </Text>

            <ul>
              <li>
                Consent: We may process your data if you have given us specific
                consent to use your personal information for a specific purpose.
              </li>
              <li>
                Legitimate Interests: We may process your data when it is
                reasonably necessary to achieve our legitimate business
                interests.
              </li>

              <li>
                Performance of a Contract: Where we have entered into a contract
                with you, we may process your personal information to fulfill
                the terms of our contract.
              </li>

              <li>
                Legal Obligations: We may disclose your information where we are
                legally required to do so in order to comply with applicable
                law, governmental requests, a judicial proceeding, court order,
                or legal process, such as in response to a court order or a
                subpoena (including in response to public authorities to meet
                national security or law enforcement requirements).
              </li>

              <li>
                Vital Interests: We may disclose your information where we
                believe it is necessary to investigate, prevent, or take action
                regarding potential violations of our policies, suspected fraud,
                situations involving potential threats to the safety of any
                person and illegal activities, or as evidence in litigation in
                which we are involved.
              </li>
            </ul>

            <Text fontSize="lg">
              More specifically, we may need to process your data or share your
              personal information in the following situations:
            </Text>

            <ul>
              <li>
                Business Transfers. We may share or transfer your information in
                connection with, or during negotiations of, any merger, sale of
                company assets, financing, or acquisition of all or a portion of
                our business to another company.
              </li>

              <li>
                Vendors, Consultants and Other Third-Party Service Providers. We
                may share your data with third-party vendors, service providers,
                Suppliers, contractors or agents who perform services for us or
                on our behalf and require access to such information to do that
                work. Examples include: negotiating air cargo quotes and
                bookings, payment processing, data analysis, email delivery,
                hosting services, customer service and marketing efforts. We may
                allow selected third parties to use tracking technology on the
                Website, which will enable them to collect data on our behalf
                about how you interact with our Website over time. This
                information may be used to, among other things, analyze and
                track data, determine the popularity of certain content, pages
                or features, and better understand online activity. Unless
                described in this notice, we do not share, sell, rent or trade
                any of your information with third parties for their promotional
                purposes.
              </li>

              <li>
                Affiliates. We may share your information with our affiliates,
                in which case we will require those affiliates to honor this
                privacy notice. Affiliates include our parent company and any
                subsidiaries, joint venture partners or other companies that we
                control or that are under common control with us.
              </li>

              <li>
                Business Partners. We may share your information with our
                business partners to offer you certain products, services or
                promotions.
              </li>
            </ul>
          </Stack>
          <Stack>
            <Heading as="h5" size="lg">
              4. WHO WILL YOUR INFORMATION BE SHARED WITH?
            </Heading>
            <Text fontSize="lg">
              In Short: We only share information with the following categories
              of third parties.
            </Text>

            <Text fontSize="lg">
              We only share and disclose your information with the following
              categories of third parties. If we have processed your data based
              on your consent and you wish to revoke your consent, please
              contact us using the contact details provided in the section below
              titled "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?".
            </Text>

            <ul>
              <li>Data Storage Service Providers</li>

              <li>Finance & Accounting Tools</li>

              <li>Payment Processors</li>

              <li>Performance Monitoring Tools</li>

              <li>Sales & Marketing Tools</li>

              <li>User Account Registration & Authentication Services</li>

              <li>Website Hosting Service Providers</li>
            </ul>
          </Stack>
          <Stack>
            <Heading as="h5" size="lg">
              5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </Heading>

            <Text fontSize="lg">
              In Short: We may use cookies and other tracking technologies to
              collect and store your information.
            </Text>
            <Text fontSize="lg">
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information. Specific
              information about how we use such technologies and how you can
              refuse certain cookies is set out in our Cookie Notice.
            </Text>
          </Stack>
          <Stack>
            <Heading as="h5" size="lg">
              6. HOW LONG DO WE KEEP YOUR INFORMATION?
            </Heading>

            <Text fontSize="lg">
              In Short: We keep your information for as long as necessary to
              fulfill the purposes outlined in this privacy notice unless
              otherwise required by law.
            </Text>

            <Text fontSize="lg">
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than twelve (12) months past the termination of the user's
              account.
            </Text>

            <Text fontSize="lg">
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </Text>
          </Stack>
          <Stack>
            <Heading as="h5" size="lg">
              7. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </Heading>
            <Text fontSize="lg">
              In Short: We aim to protect your personal information through a
              system of organizational and technical security measures.
            </Text>
            <Text fontSize="lg">
              We have implemented appropriate technical and organizational
              security measures designed to protect the security of any personal
              information we process. However, despite our safeguards and
              efforts to secure your information, no electronic transmission
              over the Internet or information storage technology can be
              guaranteed to be 100% secure, so we cannot promise or guarantee
              that hackers, cybercriminals, or other unauthorized third parties
              will not be able to defeat our security, and improperly collect,
              access, steal, or modify your information. Although we will do our
              best to protect your personal information, transmission of
              personal information to and from our Website is at your own risk.
              You should only access the Website within a secure environment.
            </Text>
          </Stack>
          <Stack>
            <Heading as="h5" size="lg">
              8. DO WE COLLECT INFORMATION FROM MINORS?
            </Heading>
            <Text fontSize="lg">
              In Short: We do not knowingly collect data from or market to
              children under 18 years of age.
            </Text>

            <Text fontSize="lg">
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Website, you represent that you are
              at least 18 or that you are the parent or guardian of such a minor
              and consent to such minor dependent's use of the Website. If we
              learn that personal information from users less than 18 years of
              age has been collected, we will deactivate the account and take
              reasonable measures to promptly delete such data from our records.
              If you become aware of any data we may have collected from
              children under age 18, please contact us at{' '}
              <a href="mailto:info@pelicargo.com">info@pelicargo.com</a>.
            </Text>
          </Stack>
          <Stack>
            <Heading as="h5" size="lg">
              9. WHAT ARE YOUR PRIVACY RIGHTS?
            </Heading>

            <Text fontSize="lg">
              In Short: In some regions, such as the European Economic Area
              (EEA) and United Kingdom (UK), you have rights that allow you
              greater access to and control over your personal information. You
              may review, change, or terminate your account at any time.
            </Text>

            <Text fontSize="lg">
              In some regions (like the EEA and UK), you have certain rights
              under applicable data protection laws. These may include the right
              (i) to request access and obtain a copy of your personal
              information, (ii) to request rectification or erasure; (iii) to
              restrict the processing of your personal information; and (iv) if
              applicable, to data portability. In certain circumstances, you may
              also have the right to object to the processing of your personal
              information. To make such a request, please use the contact
              details provided below. We will consider and act upon any request
              in accordance with applicable data protection laws.
            </Text>

            <Text fontSize="lg">
              If we are relying on your consent to process your personal
              information, you have the right to withdraw your consent at any
              time. Please note however that this will not affect the lawfulness
              of the processing before its withdrawal, nor will it affect the
              processing of your personal information conducted in reliance on
              lawful processing grounds other than consent.
            </Text>

            <Text fontSize="lg">
              If you are a resident in the EEA or UK and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your local data protection supervisory
              authority. You can find their contact details here:{' '}
              <a
                href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                target="_blank"
                rel="noreferrer"
              >
                https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
              </a>
              .
            </Text>

            <Text fontSize="lg">
              If you are a resident in Switzerland, the contact details for the
              data protection authorities are available here:{' '}
              <a
                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                target="_blank"
                rel="noreferrer"
              >
                https://www.edoeb.admin.ch/edoeb/en/home.html
              </a>
              .
            </Text>

            <strong>
              <i>Account Information</i>
            </strong>

            <Text fontSize="lg">
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:
            </Text>

            <ul>
              <li>
                Log in to your account settings and update your user account.
              </li>
            </ul>

            <Text fontSize="lg">
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our Terms of Use and/or comply with applicable legal
              requirements.
            </Text>

            <Text fontSize="lg">
              Cookies and similar technologies: Most Web browsers are set to
              accept cookies by default. If you prefer, you can usually choose
              to set your browser to remove cookies and to reject cookies. If
              you choose to remove cookies or reject cookies, this could affect
              certain features or services of our Website. To opt-out of
              interest-based advertising by advertisers on our Website visit{' '}
              <a
                href="http://www.aboutads.info/choices/"
                target="_blank"
                rel="noreferrer"
              >
                http://www.aboutads.info/choices/
              </a>
              .
            </Text>

            <Text fontSize="lg">
              Opting out of email marketing: You can unsubscribe from our
              marketing email list at any time by clicking on the unsubscribe
              link in the emails that we send or by contacting us using the
              details provided below. You will then be removed from the
              marketing email list — however, we may still communicate with you,
              for example to send you service-related emails that are necessary
              for the administration and use of your account, to respond to
              service requests, or for other non-marketing purposes. To
              otherwise opt-out, you may contact us using the contact
              information provided.
            </Text>
          </Stack>
          <Stack>
            <Heading as="h5" size="lg">
              10. CONTROLS FOR DO-NOT-TRACK FEATURES
            </Heading>
            <Text fontSize="lg">
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ("DNT") feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage no uniform technology standard for recognizing and
              implementing DNT signals has been finalized. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy notice.
            </Text>
          </Stack>
          <Stack>
            <Heading as="h5" size="lg">
              11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </Heading>

            <Text fontSize="lg">
              In Short: Yes, if you are a resident of California, you are
              granted specific rights regarding access to your personal
              information.
            </Text>

            <Text fontSize="lg">
              California Civil Code Section 1798.83, also known as the "Shine
              The Light" law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </Text>
            <Text fontSize="lg">
              If you are under 18 years of age, reside in California, and have a
              registered account with the Website, you have the right to request
              removal of unwanted data that you publicly post on the Website. To
              request removal of such data, please contact us using the contact
              information provided below, and include the email address
              associated with your account and a statement that you reside in
              California. We will make sure the data is not publicly displayed
              on the Website, but please be aware that the data may not be
              completely or comprehensively removed from all our systems (e.g.
              backups, etc.).
            </Text>

            <strong>
              <i>CCPA Privacy Notice</i>
            </strong>

            <Text fontSize="lg">
              The California Code of Regulations defines a "resident" as:
            </Text>

            <ol>
              <li>
                every individual who is in the State of California for other
                than a temporary or transitory purpose and
              </li>
              <li>
                every individual who is domiciled in the State of California who
                is outside the State of California for a temporary or transitory
                purpose
              </li>
            </ol>

            <Text fontSize="lg">
              All other individuals are defined as "non-residents."
            </Text>

            <Text fontSize="lg">
              If this definition of "resident" applies to you, we must adhere to
              certain rights and obligations regarding your personal
              information.
            </Text>

            <Text fontSize="lg">
              What categories of personal information do we collect?
            </Text>

            <Text fontSize="lg">
              We have collected the following categories of personal information
              in the past twelve (12) months:
            </Text>

            <table>
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Examples</th>
                  <th>Collected</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>A. Identifiers</td>
                  <td>
                    Contact details, such as real name, alias, postal address,
                    telephone or mobile contact number, unique personal
                    identifier, online identifier, Internet Protocol address,
                    email address and account name
                  </td>
                  <td>YES</td>
                </tr>

                <tr>
                  <td>
                    B. Personal information categories listed in the California
                    Customer Records statute
                  </td>
                  <td>
                    Name, contact information, education, employment, employment
                    history and financial information
                  </td>
                  <td>YES</td>
                </tr>

                <tr>
                  <td>
                    C. Protected classification characteristics under California
                    or federal law
                  </td>
                  <td>Gender and date of birth</td>
                  <td>NO</td>
                </tr>

                <tr>
                  <td>D. Commercial information</td>
                  <td>
                    Transaction information, purchase history, financial details
                    and payment information
                  </td>
                  <td>YES</td>
                </tr>

                <tr>
                  <td>E. Biometric information</td>
                  <td>Fingerprints and voiceprints</td>
                  <td>NO</td>
                </tr>

                <tr>
                  <td>F. Internet or other similar network activity</td>
                  <td>
                    Browsing history, search history, online behavior, interest
                    data, and interactions with our and other websites,
                    applications, systems and advertisements
                  </td>
                  <td>NO</td>
                </tr>

                <tr>
                  <td>G. Geolocation data</td>
                  <td>Device location</td>
                  <td>NO</td>
                </tr>

                <tr>
                  <td>
                    H. Audio, electronic, visual, thermal, olfactory, or similar
                    information
                  </td>
                  <td>
                    Images and audio, video or call recordings created in
                    connection with our business activities
                  </td>
                  <td>NO</td>
                </tr>

                <tr>
                  <td>I. Professional or employment-related information</td>
                  <td>
                    Business contact details in order to provide you our
                    services at a business level, job title as well as work
                    history and professional qualifications if you apply for a
                    job with us
                  </td>
                  <td>YES</td>
                </tr>

                <tr>
                  <td>J. Education Information</td>
                  <td>Student records and directory information</td>
                  <td>NO</td>
                </tr>

                <tr>
                  <td>K. Inferences drawn from other personal information</td>
                  <td>
                    Inferences drawn from any of the collected personal
                    information listed above to create a profile or summary
                    about, for example, an individual's preferences and
                    characteristics
                  </td>
                  <td>YES</td>
                </tr>
              </tbody>
            </table>

            <Text fontSize="lg">
              We may also collect other personal information outside of these
              categories instances where you interact with us in-person, online,
              or by phone or mail in the context of:
            </Text>

            <ul>
              <li>Receiving help through our customer support channels;</li>

              <li>Participation in customer surveys or contests; and</li>
              <li>
                Facilitation in the delivery of our Services and to respond to
                your inquiries.
              </li>
            </ul>

            <strong>
              <i>How do we use and share your personal information?</i>
            </strong>

            <Text fontSize="lg">
              Pelicargo Inc. collects and shares your personal information
              through:
            </Text>

            <ul>
              <li>Targeting cookies/Marketing cookies</li>
              <li>Beacons/Pixels/Tags</li>
              <li>Click redirects.</li>
            </ul>

            <Text fontSize="lg">
              More information about our data collection and sharing practices
              can be found in this privacy notice.
            </Text>

            <Text fontSize="lg">
              You may contact us by email at{' '}
              <a href="mailto:info@pelicargo.com">info@pelicargo.com</a>, or by
              referring to the contact details at the bottom of this document.
            </Text>
            <Text fontSize="lg">
              If you are using an authorized agent to exercise your right to
              opt-out we may deny a request if the authorized agent does not
              submit proof that they have been validly authorized to act on your
              behalf.
            </Text>

            <strong>
              <i>Will your information be shared with anyone else?</i>
            </strong>

            <Text fontSize="lg">
              We may disclose your personal information with our service
              providers pursuant to a written contract between us and each
              service provider. Each service provider is a for-profit entity
              that processes the information on our behalf.
            </Text>

            <Text fontSize="lg">
              We may use your personal information for our own business
              purposes, such as for undertaking internal research for
              technological development and demonstration. This is not
              considered to be "selling" of your personal data.
            </Text>

            <Text fontSize="lg">
              Pelicargo Inc. has disclosed the following categories of personal
              information to third parties for a business or commercial purpose
              in the preceding twelve (12) months:
            </Text>

            <ul>
              <li>
                Category B. Personal information, as defined in the California
                Customer Records law, such as your name, contact information,
                education, employment, employment history and financial
                information.
              </li>

              <li>
                Category D. Commercial information, such as transaction
                information, purchase history, financial details and payment
                information.
              </li>
            </ul>

            <Text fontSize="lg">
              The categories of third parties to whom we disclosed personal
              information for a business or commercial purpose can be found
              under "WHO WILL YOUR INFORMATION BE SHARED WITH?".
            </Text>

            <Text fontSize="lg">
              Pelicargo Inc. has not sold any personal information to third
              parties for a business or commercial purpose in the preceding
              twelve (12) months. Pelicargo Inc. will not sell personal
              information in the future belonging to website visitors, users and
              other consumers.
            </Text>

            <strong>
              <i>Your rights with respect to your personal data</i>
            </strong>

            <Text fontSize="lg">
              Right to request deletion of the data - Request to delete
            </Text>

            <Text fontSize="lg">
              You can ask for the deletion of your personal information. If you
              ask us to delete your personal information, we will respect your
              request and delete your personal information, subject to certain
              exceptions provided by law, such as (but not limited to) the
              exercise by another consumer of his or her right to free speech,
              our compliance requirements resulting from a legal obligation or
              any processing that may be required to protect against illegal
              activities.
            </Text>

            <strong>
              <i>Right to be informed - Request to know</i>
            </strong>

            <Text fontSize="lg">
              Depending on the circumstances, you have a right to know:
            </Text>

            <ul>
              <li>whether we collect and use your personal information;</li>

              <li>the categories of personal information that we collect;</li>

              <li>
                the purposes for which the collected personal information is
                used;
              </li>

              <li>
                whether we sell your personal information to third parties;
              </li>

              <li>
                the categories of personal information that we sold or disclosed
                for a business purpose;
              </li>

              <li>
                the categories of third parties to whom the personal information
                was sold or disclosed for a business purpose; and
              </li>

              <li>
                the business or commercial purpose for collecting or selling
                personal information.
              </li>
            </ul>

            <Text fontSize="lg">
              In accordance with applicable law, we are not obligated to provide
              or delete consumer information that is de-identified in response
              to a consumer request or to re-identify individual data to verify
              a consumer request.
            </Text>

            <strong>
              <i>
                Right to Non-Discrimination for the Exercise of a Consumer's
                Privacy Rights
              </i>
            </strong>

            <Text fontSize="lg">
              We will not discriminate against you if you exercise your privacy
              rights.
            </Text>

            <strong>
              <i>Verification process</i>
            </strong>

            <Text fontSize="lg">
              Upon receiving your request, we will need to verify your identity
              to determine you are the same person about whom we have the
              information in our system. These verification efforts require us
              to ask you to provide information so that we can match it with
              information you have previously provided us. For instance,
              depending on the type of request you submit, we may ask you to
              provide certain information so that we can match the information
              you provide with the information we already have on file, or we
              may contact you through a communication method (e.g. phone or
              email) that you have previously provided to us. We may also use
              other verification methods as the circumstances dictate.
            </Text>

            <Text fontSize="lg">
              We will only use personal information provided in your request to
              verify your identity or authority to make the request. To the
              extent possible, we will avoid requesting additional information
              from you for the purposes of verification. If, however, we cannot
              verify your identity from the information already maintained by
              us, we may request that you provide additional information for the
              purposes of verifying your identity, and for security or
              fraud-prevention purposes. We will delete such additionally
              provided information as soon as we finish verifying you.
            </Text>

            <strong>
              <i>Other privacy rights</i>
            </strong>

            <ul>
              <li>you may object to the processing of your personal data.</li>
              <li>
                you may request correction of your personal data if it is
                incorrect or no longer relevant, or ask to restrict the
                processing of the data.
              </li>

              <li>
                you can designate an authorized agent to make a request under
                the CCPA on your behalf. We may deny a request from an
                authorized agent that does not submit proof that they have been
                validly authorized to act on your behalf in accordance with the
                CCPA.
              </li>

              <li>
                you may request to opt-out from future selling of your personal
                information to third parties. Upon receiving a request to
                opt-out, we will act upon the request as soon as feasibly
                possible, but no later than 15 days from the date of the request
                submission.
              </li>
            </ul>

            <Text fontSize="lg">
              To exercise these rights, you can contact us by email at{' '}
              <a href="mailto:info@pelicargo.com">info@pelicargo.com</a>, or by
              referring to the contact details at the bottom of this document.
              If you have a complaint about how we handle your data, we would
              like to hear from you.
            </Text>
          </Stack>
          <Stack>
            <Heading as="h5" size="lg">
              12. DO WE MAKE UPDATES TO THIS NOTICE?
            </Heading>

            <Text fontSize="lg">
              In Short: Yes, we will update this notice as necessary to stay
              compliant with relevant laws.
            </Text>

            <Text fontSize="lg">
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated "Revised" date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </Text>
          </Stack>
          <Stack>
            <Heading as="h5" size="lg">
              13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </Heading>

            <Text fontSize="lg">
              If you have questions or comments about this notice, you may email
              us at <a href="mailto:info@pelicargo.com">info@pelicargo.com</a>{' '}
              or by post to:
            </Text>
            <Text fontSize="lg">
              Pelicargo Inc.
              <br />
              770 Massachusetts Ave.
              <br />
              P.O. Box 390054
              <br />
              Cambridge, MA 02139
              <br />
              United States
            </Text>
          </Stack>
          <Stack>
            <Heading as="h5" size="lg">
              14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </Heading>

            <Text fontSize="lg">
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, change that information, or delete it in some
              circumstances. To request to review, update, or delete your
              personal information, please contact us at{' '}
              <a href="mailto:info@pelicargo.com">info@pelicargo.com</a>.
            </Text>
          </Stack>
        </Stack>
      </Container>
    </Page>
  );
};
