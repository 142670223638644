import { trpc, TrpcInputs } from '../../config/trpc';
import { useAuth } from '../auth/useAuth';

export const useAccounts = (queryParams: TrpcInputs['getAllAccounts']) => {
  const { isLoggedIn } = useAuth();

  const { isLoading, data, error, refetch } = trpc.getAllAccounts.useQuery(
    queryParams,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: isLoggedIn,
    },
  );

  const itemCount = data?.[0];
  const items = data?.[1];

  return {
    data: items,
    count: itemCount,
    isLoading,
    error: error?.message,
    refetch,
  };
};
