import { Box, Icon, Popover, Stack, Text } from '@chakra-ui/react';
import { SelectOption, ServiceType } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { HiChevronRight } from 'react-icons/hi';
import { HiBarsArrowDown } from 'react-icons/hi2';
import { useMediaQuery } from 'usehooks-ts';

import { QuoteFilters } from '../hooks/useQuoteFilters';
import { FilterPopoverButton, FilterPopoverContent } from './FilterPopover';
import { Hint } from './Hint';
import { AircraftFilter } from './QuoteListFilter/AircraftFilter';
import { AirlineFilter } from './QuoteListFilter/AirlineFilter';
import { AirportFilter } from './QuoteListFilter/AirportFilter';
import { ServiceTypeFilter } from './QuoteListFilter/ServiceTypeFilter';
import { SortFilter } from './QuoteListFilter/SortFilter';
import { StopFilter } from './QuoteListFilter/StopFilter';

type Props = {
  filters: QuoteFilters;
  origins: string[];
  destinations: string[];
  airlines: SelectOption[];
  handleCheckboxFilterChange: (key: string) => (nextValue: string[]) => void;
  handleRadioFilterChange: (key: string) => (nextValue: string) => void;
  handleToggleAll: (
    key: string,
    value: string | string[] | ServiceType,
  ) => void;
};

export const QuoteListFilters = ({
  filters,
  origins = [],
  destinations = [],
  airlines = [],
  handleCheckboxFilterChange,
  handleRadioFilterChange,
}: Props) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [view, setView] = useState(null);

  const handleChangeView = (nextView: string) => () => {
    setView(nextView);
  };

  if (isMobile) {
    return (
      <Stack direction="row" align="center" justify="space-between">
        <Hint name="quoteListing-filters" placement="auto-start">
          <Popover placement="bottom-start">
            <FilterPopoverButton label="Filters" />
            <FilterPopoverContent>
              <Show if={!view}>
                <Stack spacing="2" pt="2">
                  <Stack
                    direction="row"
                    align="center"
                    justify="space-between"
                    onClick={handleChangeView('airlines')}
                  >
                    <Text textStyle="captionRegular">Airlines</Text>
                    <Icon as={HiChevronRight} />
                  </Stack>
                  <Stack
                    direction="row"
                    align="center"
                    justify="space-between"
                    onClick={handleChangeView('airports')}
                  >
                    <Text textStyle="captionRegular">Airports</Text>
                    <Icon as={HiChevronRight} />
                  </Stack>
                  <Stack
                    direction="row"
                    align="center"
                    justify="space-between"
                    onClick={handleChangeView('stops')}
                  >
                    <Text textStyle="captionRegular">Stops</Text>
                    <Icon as={HiChevronRight} />
                  </Stack>
                  <Stack
                    direction="row"
                    align="center"
                    justify="space-between"
                    onClick={handleChangeView('aircraftType')}
                  >
                    <Text textStyle="captionRegular">Aircraft Type</Text>
                    <Icon as={HiChevronRight} />
                  </Stack>
                  <Stack
                    direction="row"
                    align="center"
                    justify="space-between"
                    onClick={handleChangeView('serviceType')}
                  >
                    <Text textStyle="captionRegular">Service Type</Text>
                    <Icon as={HiChevronRight} />
                  </Stack>
                </Stack>
              </Show>
              <Show if={!!view}>
                <Show if={view === 'airlines'}>
                  <AirlineFilter
                    filters={filters}
                    airlines={airlines}
                    handleCheckboxFilterChange={handleCheckboxFilterChange}
                    resetView={() => setView(null)}
                  />
                </Show>
                <Show if={view === 'airports'}>
                  <AirportFilter
                    filters={filters}
                    origins={origins}
                    destinations={destinations}
                    handleCheckboxFilterChange={handleCheckboxFilterChange}
                    resetView={() => setView(null)}
                  />
                </Show>
                <Show if={view === 'stops'}>
                  <StopFilter
                    filters={filters}
                    handleRadioFilterChange={handleRadioFilterChange}
                    resetView={() => setView(null)}
                  />
                </Show>
                <Show if={view === 'aircraftType'}>
                  <AircraftFilter
                    filters={filters}
                    handleRadioFilterChange={handleRadioFilterChange}
                    resetView={() => setView(null)}
                  />
                </Show>
                <Show if={view === 'serviceType'}>
                  <ServiceTypeFilter
                    filters={filters}
                    handleRadioFilterChange={handleRadioFilterChange}
                    resetView={() => setView(null)}
                  />
                </Show>
              </Show>
            </FilterPopoverContent>
          </Popover>
        </Hint>
        <Popover placement="bottom-start">
          <FilterPopoverButton
            label={`Sort: ${filters.sort}`}
            icon={HiBarsArrowDown}
          />
          <FilterPopoverContent>
            <SortFilter
              filters={filters}
              handleRadioFilterChange={handleRadioFilterChange}
              resetView={() => setView(null)}
            />
          </FilterPopoverContent>
        </Popover>
      </Stack>
    );
  }

  return (
    <Stack direction="row" align="center" spacing="3">
      <Popover placement="bottom-start">
        <FilterPopoverButton
          label="Airlines"
          hasValue={!isEmpty(filters.airlines)}
        />
        <FilterPopoverContent>
          <AirlineFilter
            filters={filters}
            airlines={airlines}
            handleCheckboxFilterChange={handleCheckboxFilterChange}
            resetView={() => setView(null)}
          />
        </FilterPopoverContent>
      </Popover>

      <Popover placement="bottom-start">
        <FilterPopoverButton
          label="Airports"
          hasValue={!isEmpty(filters.origins) || !isEmpty(filters.destinations)}
        />
        <FilterPopoverContent>
          <AirportFilter
            filters={filters}
            origins={origins}
            destinations={destinations}
            handleCheckboxFilterChange={handleCheckboxFilterChange}
            resetView={() => setView(null)}
          />
        </FilterPopoverContent>
      </Popover>

      <Popover placement="bottom-start">
        <FilterPopoverButton label="Stops" hasValue={filters.stops !== 'any'} />
        <FilterPopoverContent>
          <StopFilter
            filters={filters}
            handleRadioFilterChange={handleRadioFilterChange}
            resetView={() => setView(null)}
          />
        </FilterPopoverContent>
      </Popover>
      <Popover placement="bottom-start">
        <FilterPopoverButton
          label="Aircrafts"
          hasValue={filters.aircraft !== 'any'}
        />
        <FilterPopoverContent>
          <AircraftFilter
            filters={filters}
            handleRadioFilterChange={handleRadioFilterChange}
            resetView={() => setView(null)}
          />
        </FilterPopoverContent>
      </Popover>
      <Hint name="quoteListing-filters" placement="top-end">
        <Box>
          <Popover placement="bottom-start">
            <FilterPopoverButton
              label="Service Type"
              hasValue={filters.serviceType !== ServiceType.General}
            />
            <FilterPopoverContent>
              <ServiceTypeFilter
                filters={filters}
                handleRadioFilterChange={handleRadioFilterChange}
                resetView={() => setView(null)}
              />
            </FilterPopoverContent>
          </Popover>
        </Box>
      </Hint>

      <Box ml="auto">
        <Popover placement="bottom-start">
          <FilterPopoverButton
            label={`Sort: ${filters.sort}`}
            icon={HiBarsArrowDown}
          />
          <FilterPopoverContent>
            <SortFilter
              filters={filters}
              handleRadioFilterChange={handleRadioFilterChange}
              resetView={() => setView(null)}
            />
          </FilterPopoverContent>
        </Popover>
      </Box>
    </Stack>
  );
};
