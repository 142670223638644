import mixpanel from 'mixpanel-browser';

export enum MixpanelEvent {
  SessionStart = 'Session Start',
  // ClickedSignup = 'Clicked Signup',
  ChangeSelect = 'Change Select',
  // Register - Signup
  SignupView = 'Signup View',
  SignupSubmit = 'Signup Submit',
  // Register - Confirm
  ConfirmView = 'Confirm View',
  ConfirmSubmit = 'Confirm Submit',
  // Register - Personal
  PersonalView = 'Personal View',
  PersonalSubmit = 'Personal Submit',
  // Register - Company
  CompanyView = 'Company View',
  CompanySubmit = 'Company Submit',
  CompanyBackToInvites = 'Company Back To Invites',
  // Register - CompanySelect
  CompanySelectView = 'Company Select View',
  CompanySelectSubmit = 'Company Select Submit',
  CompanySelectNewCompany = 'Company Select New Company',
  // Register - Signature
  SignatureView = 'Signature View',
  SignatureSubmit = 'Signature Submit',
  // Register - Invite
  InviteView = 'Invite View',
  InviteSubmit = 'Invite Submit',
}

export const trackEvent = (
  eventName: MixpanelEvent,
  properties?: { [key: string]: any },
): void => {
  mixpanel.track(eventName, properties);
};
